import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiAuthService } from 'lexi-auth';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';


@Component({
  selector: 'lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public config:any = {};
  public data:any = {};
  private environment: any;
  public placeholder:any = {"email_placeholder": "Enter Your Email Address"};
  public dsme:boolean = false;

  constructor(private auth: LexiAuthService, 
    private ldt: LexiDashforgeTopService, 
    private api: ApiService,
    @Inject("environment") environment: any) {
      this.environment = environment;
      this.config = this.ldt.get();

      if(this.environment.email_placeholder) {
        this.placeholder.login_username_placeholder = this.environment.email_placeholder
      }

      if(this.environment.dsme) {
        this.dsme = this.environment.dsme
      }
     }
  
     async submit() {

      const t = await this.api.post(this.environment.forgot_password_url ?? "/users/forgot-password", this.data);
  
      alert(t)
    }

  ngOnInit(): void {
  }

}
