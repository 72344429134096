import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class LexiViewService {

    private func: any = {};

    constructor(@Inject(DOCUMENT) private document:Document) { }

    setFunction(name: string, func:any) {
        this.func[name] = func;
    }

    getFunction(name: string, val:any) {
        return () => {return this.func[name](val)};
    }

    public exportCSV(filename: any, csvContent: any) {
        const a = document.createElement('a');
        const blob = new Blob(csvContent, { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename+'.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();        
    }

    public exportEXCEL(filename: any, content: any) {  
        const a = document.createElement('a');
        const blob = new Blob(content, { type: 'text/xlsx;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        
        a.href = url;
        a.download = filename+'.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();   
    }  

}
