import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-json',
  templateUrl: './json.component.html',
  styleUrls: ['./json.component.css']
})
export class JsonComponent implements OnInit {

  @Input() field: any = {};
  @Input() data: any = {};
  constructor() { }

  ngOnInit(): void {
  }

}
