  <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
    <ng-container *ngIf="field.descp">
      <br>
      <small class="text-muted">{{field.descp}}</small>
  </ng-container>
  </label>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">{{selected ? selected.name + ' (' + selected.dial_code + ')' : 'Select Country'}}</button>
      <div class="dropdown-menu overflow-scroll" style="max-height: 75vh;">
        <a *ngFor="let option of options" class="dropdown-item" (click)="countryChange(option)">{{option.name + ' (' + option.dial_code + ')' }}</a>
      </div>
    </div>
    <input type="text" class="form-control form-control-sm" [id]="field.mapping.key" [(ngModel)]="data.contact" [disabled]="field.editable && field.editable == 0" (input)="onChangeInput()">  
  </div>

  <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" [disabled]="field.editable && field.editable == 0" hidden>

  