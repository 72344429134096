import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'lib-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.css']
})
export class PageNavigationComponent implements OnInit {

  @Output() outputEvent = new EventEmitter(); 
  @Input() set pagination (val:any) {
    if(val) {
      this.pages = val;
      const input = document.getElementById('perPage') as HTMLInputElement | null;
      if (input != null) {
        input.value = this.pages.perPage;
      }
    }
  }

  public pages:any = {page_nat: 1, current_page: 1, perPage: 25};
  constructor() { }

  ngOnInit(): void {
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  selectedPage(page:any){
    this.pages.current_page = page;
    this.outputEvent.emit(this.pages)
  }

  previous(){
    this.pages.current_page = this.pages.current_page == 1 ? 1 : this.pages.current_page-1;
    this.outputEvent.emit(this.pages)
  }

  next(){
    this.pages.current_page = this.pages.current_page == this.numSequence(this.pages.page_nat).length ? this.numSequence(this.pages.page_nat).length : this.pages.current_page+1;
    this.outputEvent.emit(this.pages)
  }

  getPages(){
    this.pages.perPage = parseInt((<HTMLInputElement>document.getElementById("perPage")).value)
    this.outputEvent.emit(this.pages)
  }
}
