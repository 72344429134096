<label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
    <ng-container *ngIf="field.descp">
        <br>
        <small class="text-muted">{{field.descp}}</small>
    </ng-container>
</label>
<table class="table table-sm">
    <tr>
        <th></th>
        <th>Item</th>
        <th>Value (RM)</th>
        <th>Warranty (Month)</th>
    </tr>
    <tr *ngFor="let warranty of warranties; let i = index">
        <td><button type="button" (click)="addRow()">+</button></td>
        <td><input class="form-control w-100" type="text" [(ngModel)]="warranty.title" (change)="onchange()"/></td>
        <td><input class="form-control w-100" type="number" [(ngModel)]="warranty.value" (change)="onchange()"/></td>
        <td><select class="form-control w-100" [(ngModel)]="warranty.month" (change)="onchange()">
            <option value="1">1 Month</option>
            <option value="2">2 Months</option>
            <option value="3">3 Months</option>
            <option value="6">6 Months</option>
            <option value="12">12 Months</option>
            <option value="18">18 Months</option>
            <option value="24">2 Years</option>
            <option value="36">3 Years</option>
            <option value="48">4 Years</option>
            <option value="60">5 Years</option>
            <option value="72">6 Years</option>
            <option value="84">7 Years</option>
            <option value="96">8 Years</option>
            <option value="108">9 Years</option>
            <option value="120">10 Years</option>
        </select></td>
        <td><button type="button" (click)="removeRow(i)">-</button></td>
    </tr>
</table>