import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.css']
})
export class MultiCheckboxComponent {

  public ctrl:any;
  public fields:any;
  public options:any = [];

  @Input() set field(val:any) {
    this.fields = val
    this.options = this.fields.options;
  }
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    if(!this.ctrl.value) {
      this.ctrl.value = []
    } else {
      this.options.forEach((element:any) => {
        if (this.ctrl.value.indexOf(element.val.toString()) > -1) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    }
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { }

  onCheckboxChange(e:any) {
    if (e.target.checked) {
      this.ctrl.value.push(e.target.value)
    } else {
      let i = this.ctrl.value.find(e.target.value)
      if( i > -1) {
        this.ctrl.value.slice(i, 1)
      }
    }
  }
}
