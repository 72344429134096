<nav class="navbar navbar-dark bg-dark">
    <h4 class="navbar-brand"><b>LEXI GLOBAL SDN BHD</b></h4>
</nav>

<div class="py-5 px-4 vh-100 bg-light">
    <ng-container *ngIf="isLoading">
        <lib-dot></lib-dot>
    </ng-container>
    <!-- <button class="btn btn-primary" (click)="resendMail()">Resend Invitation Email</button> -->
    <!-- <button class="btn btn-dark rounded-0 px-5" (click)="trackCosting()">COSTING TRACKER</button> -->
</div>