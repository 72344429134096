import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent {

  public ctrl:any;
  public fields:any;
  public options:any;

  // @Input() field: any = {};
  @Input() set field(val:any) {
    this.fields = val
    this.options = this.fields.options;
  }
  @Input() set setRelated(val:any){
    if(!val) {
      return;
    }
    
    this.options = [];
    
    this.options = this.fields.options.filter((item:any) => {
        return item['related'] == val;
    });
  };
  
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl

    if(!this.ctrl.value && this.ctrl.value == "" && this.options.length > 0) {
      let first = this.options[0];
      if(!first) {
        return;
      }
      this.ctrl.value = first.val
    }
  }

  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() {}

  detectChanges($e:any) {
    let d:any = {key: this.fields.mapping.key, togglers: this.fields.mapping.related_toggler}
    if(this.fields.mapping.conditional){
      d['conditional'] = this.fields.mapping.conditional;
    }
    this.outputEvent.emit(d)
  }

  clearSelect() {
    this.ctrl.reset()    
  }
}
