import { Component, Inject } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiAuthService } from 'lexi-auth';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';

@Component({
  selector: 'lib-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  public config:any = {};
  public data:any = {};
  private environment: any;
  public placeholder:any = {"login_username_placeholder": "Enter Your Email Address", "login_password_placeholder": "Enter Your Password"};
  public dsme: boolean = false;

  constructor(
    private auth: LexiAuthService, 
    private ldt: LexiDashforgeTopService, 
    private api: ApiService,
    @Inject("environment") environment: any
  ) { 
    this.environment = environment;
    this.config = this.ldt.get();

    if(this.environment.login_username_placeholder) {
      this.placeholder.login_username_placeholder = this.environment.login_username_placeholder
    }

    if(this.environment.login_password_placeholder) {
      this.placeholder.login_password_placeholder = this.environment.login_password_placeholder
    }

    if(this.environment.dsme) {
      this.dsme = this.environment.dsme
    }
    
  }

  async submit() {

    const t = await this.api.post(this.environment.register_url ?? "/users/register", this.data);

    if(t.status) {
      this.api.valueAppender("token", t.token)
      this.auth.setToken(t.token);
      this.auth.update(true)
      this.auth.setUser(t.user);
      this.auth.redirect(this.environment.success_login_url ?? ["/post"])
      return;
    }

    alert(t)
  }

}
