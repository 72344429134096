import { NgModule } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { LexiTranslationService } from './lexi-translation.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
      return `MT::${params.key}`;
  }
}

export function HttpLoaderFactory(_httpBackend: HttpClient, ts: LexiTranslationService) {

  let arr:any = [];
  let load = ts.get();

  load.forEach((v:any) => {
    v.prefix = v.path;
    v.suffix = '.json'
  })

  return new MultiTranslateHttpLoader(_httpBackend, load);
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, LexiTranslationService]
      },
      isolate: false,
      extend: true
    })
  ],
  providers: [
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler
    }
  ]
})

export class LexiTranslationCoreModule { }

export interface TranslationResource {
  path: string;
  extension?: string;
}