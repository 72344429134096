import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.css']
})
export class MultiSelectCheckboxComponent {

  public ctrl:any;
  public fields:any;
  public options:any = [];

  dropdownList:any = [];
  selectedItems:any = [];
  dropdownSettings:IDropdownSettings = {};

  @Input() set setRelated(val:any){
    this.options = [];    
    this.options = this.fields.options.filter((item:any) => {
        return item['related'] == val;
      });
  };
  @Input() set field(val:any) {
    this.fields = val
    this.options = this.fields.options;
  }
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    
    if(!this.ctrl.value) {
      this.ctrl.value = []
    }
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'val',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  detectChanges() {
    this.outputEvent.emit(this.ctrl.value)
  }

  onItemSelect(item: any) {
    this.detectChanges()
  }
  onSelectAll(items: any) {
    this.detectChanges()
  }

  onDeSelect(item: any) {
      this.detectChanges()
  }

  onDeSelectAll(items: any) {
    this.detectChanges()
  }
}
