import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-vertical-warranty',
  templateUrl: './vertical-warranty.component.html',
  styleUrls: ['./vertical-warranty.component.css']
})
export class VerticalWarrantyComponent implements OnInit {

  @Input() field: any = {};
  @Input() data: any = {};
  constructor() { }

  ngOnInit(): void {
    console.log(this.data)
  }

}
