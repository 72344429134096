import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'lexi-api';
import { CookieService } from 'ngx-cookie-service';
type LangType = {title?:string, code: string, default?: boolean};
type LangTypeArray = Array<LangType>;

@Injectable({
  providedIn: 'root'
})
export class LexiTranslationHelper {
  private environment:any;

  constructor(private translate: TranslateService, private api: ApiService, private cookieService: CookieService, @Inject('environment') environment:any) { 
    this.environment = environment;
  }

  private _availables = [{title: "English", code: "en", default: true}, {title: "Malay", code: "ms", default: false}, {title: "中文", code: "zh", default: false}];

  private _allowed: LangTypeArray = [{title: "English", code: "en", default: true}];

  public setAvailable(langs: LangTypeArray = [{title: "English", code: "en", default: true}, {title: "Malay", code: "ms", default: false}, {title: "中文", code: "zh", default: false}] ) {

    if(langs.length == 0) {
      return this._allowed;
    }

    const _allowed = [];
    for(var x: number = 0; x < langs.length; x++) {
      const current:LangType = langs[x];
      const lang = this._availables.find((x) => x.code === current.code);

      if(lang){
        
        lang.default = false;

        if(current.default) {
          this.setDefault(lang.code);
          lang.default = true;
        }

        _allowed.push(lang);
      }
    }

    const lang = this.cookieService.get('lang');

    if(lang) {
      this.switch(lang)
    }

    if(_allowed.length > 0) {
      this._allowed = _allowed;
    }

    return this._allowed;
  }

  public getAvailable() {
    return this._allowed;
  }

  public setDefault(lang: string) {
    this.api.valueAppender("lang", lang);
    this.translate.setDefaultLang(lang);
  }

  public current() {
    const current = this.translate.currentLang;
    if(current){
      return this._availables.find((x) => x.code == current);
    }

    const _default = this._availables.find((x) => x.default);

    if(_default) {
      return _default
    }

    return this._availables[0];
  }

  public switch(lang:string | LangType) {

    const expiryDate: Date = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 12)
    
    if (typeof lang === "object") {
      this.api.valueAppender("lang", lang.code);
      this.cookieService.set('lang', lang.code, expiryDate, '/');
      this.translate.use(lang.code);
      return;
    }

    this.api.valueAppender("lang", lang);
    this.cookieService.set('lang', lang, expiryDate, '/');
    this.translate.use(lang);
  }

  public change() {
    return this.translate.onLangChange;
  }

  public instant(key:any) {

    if(!this.environment || !this.environment.translate || !key) {
      return key;
    }
    
    return this.translate.instant(key.toLowerCase().replace(/ /g, "_"));
  }

  public dinstant(key:any) {
    
    if(!key) {
      return key;
    }

    return this.translate.instant(key);
  }
}
