import { Component, ViewContainerRef, Inject } from '@angular/core';
import { TableMenuService } from 'lexi-table-menu';
import { Router, NavigationEnd, Event } from '@angular/router'
import { LexiAuthService } from 'lexi-auth';
import { MenuHelperService } from 'menu-helper';
import { ApiService } from 'lexi-api';
import { LexiTableV2Service } from 'lexi-table-v2';
import { LexiViewService } from 'lexi-view';
import { LexiNotifyService } from 'lexi-notify';
import { WhbDataService } from 'whb-data';
import { LexiTranslationService } from 'lexi-translation';
declare var PerfectScrollbar: any;
declare var $: any;  
import { io, Socket } from "socket.io-client";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'waihongbrothers';

  private headerMenu:any = null;
  private loginStatus:boolean = false;
  private socket: Socket;

  constructor(
    @Inject("environment") private environment: any,
    private menu: TableMenuService, 
    private menuHelper : MenuHelperService, 
    private api: ApiService,
    private router: Router, 
    private auth: LexiAuthService,
    private tableService: LexiTableV2Service,
    private viewService: LexiViewService,
    private notify: LexiNotifyService,
    private dataService: WhbDataService,
    private translationService:LexiTranslationService
  ) {
    this.socket = io("https://dec1b5f-6a6b-4999-a357-87a182e6ea6f.lexiglobal.my",  {
      path: "/sock",
        extraHeaders: {
      "x-profile":"da522db6-d6c2-4f9a-ab25-65c86d706bdb",
      "x-api-key":"IS3t5wm02Yrf0rooBpMlar1B3YHtua3Get7YLZlHXJB9RTVCiNWPDqLFS10MufFxiEaDODyIn6XUCMcr1ljKLrGkBhfopQj0UyX16YUUWNcsDVih6SGTv1vbuoIdl0mmKlfN9qwHljPTIMkk30z3Hr6KoKpRbWV6zSO43Zgqv2QzmRSGwNqMUn08uDtPo0M7GZgBu0HrCgfKLDOIoARZnWK9BWw862DdlQM3mz7mwdzEzD3arvSPWyCcS2BJ7hyp"
    }});

    this.socket.on("connect", function () {
      console.log("Connected!");
    });
    this.api.valueAppender("token", this.auth.getToken())
    this.api.valueAppender("branch_uuid", localStorage.getItem("branch"))

    this.auth.getLoginStatus.subscribe(async (v:any) => {
      if(this.loginStatus !== v){
        this.loginStatus = v;
        this.getMenu()
      }
    })

    this.loadFunction()
  
    let func = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }

      vcr.clear()
      let component = ((await import("whb-task")).ProgressDashboardComponent)
      let ci = vcr.createComponent(component);
          ci.instance.task_uuid = uuid;        
    }
    this.menu.append("task-progress", func);


    let func2 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("tdb")).MediaUploadComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("media-upload", func2);


    let func3 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("tdb")).ReportTableViewComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("report-table", func3);


    let func4 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-document")).DocumentComponent)
      let ci = vcr.createComponent(component);
          ci.instance.document_uuid = uuid;   
          ci.instance.parent_uuid = parentUUID;   
          ci.instance.type = 'quotation';     
    }
    this.menu.append("whb-quotation", func4);


    let func5 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      var path = {get: '/users/get/customer-document', remove: '/users/remove/customer-document', save: '/users/save/customer-document'}
      
      let component = ((await import("whb-task")).MediaComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;        
          ci.instance.type = "customer";   
          ci.instance.pathLink = path;
          ci.instance.title = "Media Management"
    }
    this.menu.append("whb-media-upload", func5);

    // let func6 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
    //   vcr.clear()
    //   var path = {get: '/task/get/expenses-document', remove: '/task/remove/expenses-document', save: '/task/save/expenses-document'}

    //   let component = ((await import("whb-task")).MediaComponent)
    //   let ci = vcr.createComponent(component);
    //       ci.instance.parent_uuid = uuid;  
    //       ci.instance.type = "task_expenses";
    //       ci.instance.pathLink = path;      
    // }
    // this.menu.append("expended-upload", func6);


    let func7 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskDailyRecordComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;  
    }
    this.menu.append("daily-record", func7);


    let func8 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {

      if(!vcr) {
        return;
      }

      vcr.clear()
      let component = ((await import("whb-document")).DocumentComponent)
      let ci = vcr.createComponent(component);
          ci.instance.document_uuid = uuid;   
          ci.instance.parent_uuid = parentUUID;   
          ci.instance.type = 'estimate';     
    }
    this.menu.append("whb-estimate", func8);


    let func9 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-document")).DocumentComponent)
      let ci = vcr.createComponent(component);
          ci.instance.document_uuid = uuid;   
          ci.instance.parent_uuid = parentUUID;   
          ci.instance.type = 'proforma';     
    }
    this.menu.append("whb-proforma", func9);


    let func10 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-document")).DocumentComponent)
      let ci = vcr.createComponent(component);
          ci.instance.document_uuid = uuid;   
          ci.instance.parent_uuid = parentUUID;   
          ci.instance.type = 'invoice';     
    }
    this.menu.append("whb-invoice", func10);


    // let func11 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
    //   vcr.clear()
    //   let component = ((await import("whb-document")).DocumentComponent)
    //   let ci = vcr.createComponent(component);
    //       ci.instance.document_uuid = uuid;   
    //       ci.instance.parent_uuid = parentUUID;   
    //       ci.instance.type = 'receipt';     
    // }
    // this.menu.append("whb-receipt", func11);


    let func12 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-document")).AfterCareComponent)
      let ci = vcr.createComponent(component);
      // let component = ((await import("whb-document")).DocumentComponent)
      // let ci = vcr.createComponent(component);
      //     ci.instance.document_uuid = uuid;   
      //     ci.instance.parent_uuid = parentUUID;   
      //     ci.instance.type = 'after-care';     
    }
    this.menu.append("whb-after-care", func12);


    let func13 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }

      vcr.clear()
      var path = {get: '/task/get/project/site-photo', remove: '/task/remove/project/site-photo', save: '/task/save/project/site-photo/admin'}
      
      let component = ((await import("whb-task")).MediaComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;        
          ci.instance.type = "task";   
          ci.instance.pathLink = path;
          ci.instance.title = "Inspection Photo Management"
    }
    this.menu.append("task-site-photo", func13);


    let func14 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskProgressComponent)
      let ci = vcr.createComponent(component);
    }
    this.menu.append("task-template", func14);


    let func15 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskBudgetComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = parentUUID;
      ci.instance.data_uuid = uuid;
    }
    this.menu.append("task-budget", func15);


    let func16 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskCostingComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = uuid;
    }
    this.menu.append("task-costing", func16);


    let func17 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskFollowupComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = uuid;
    }
    this.menu.append("task-followUp", func17);


    let func18 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }

      vcr.clear()
      var path = {get: '/task/get/project/progress-photo', remove: '/task/remove/project/progress-photo', save: '/task/save/project/progress-photo'}
      
      let component = ((await import("whb-task")).MediaComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;        
          ci.instance.type = "task";   
          ci.instance.pathLink = path;
          ci.instance.title = "Work Progress Photo Management"
    }
    this.menu.append("task-progress-photo", func18);


    let func19 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskStockTransactionComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = parentUUID;
    }
    this.menu.append("stock-transaction", func19);


    let func20 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskStockTransactionListComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = parentUUID;
    }
    this.menu.append("stock-transaction-list", func20);


    let func21 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskStockSupplierListComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = parentUUID;
    }
    this.menu.append("stock-supplier-list", func21);


    let func22 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskAftercareProgressComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = uuid;
    }
    this.menu.append("task-aftercare-progress", func22);


    let func23 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }

      vcr.clear()
      var path = {get: '/task/get/project/aftercare-photo', remove: '/task/remove/project/aftercare-photo', save: '/task/save/project/aftercare-photo/admin'}
      
      let component = ((await import("whb-task")).MediaComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;        
          ci.instance.type = "task";   
          ci.instance.pathLink = path;
          ci.instance.title = "Aftercare Photo Management"
    }
    this.menu.append("task-aftercare-photo", func23);


    let func24 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }

      vcr.clear()
      var path = {get: '/task/get/project/attachments', remove: '/task/remove/project/attachments', save: '/task/save/project/attachments/admin'}
      
      let component = ((await import("whb-task")).MediaComponent)
      let ci = vcr.createComponent(component);
          ci.instance.parent_uuid = uuid;        
          ci.instance.type = "task";   
          ci.instance.pathLink = path;
          ci.instance.title = "Attachments Management"
    }
    this.menu.append("task-attachments", func24);

    let func25 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("whb-task")).TaskCostingAftercareComponent)
      let ci = vcr.createComponent(component);
      ci.instance.parent_uuid = uuid;
    }
    this.menu.append("task-aftercare-costing", func25);

  }

  async getMenu() {
    this.menuHelper.reset()

    const menu:any = await this.api.post('/users/menu/get', {token: this.auth.getToken()})
    this.menuHelper.set(menu)
    setTimeout(() => {
      this.initMenu();
    }, 0)
  }

  initMenu() {
    var ps : any = null;
    const initPerfectScroll = function() {
      if (ps) {
        return;
      }

      ps = new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true,
        wheelSpeed: 2,
        wheelPropagation: true,
      });
    }

    if(window.matchMedia('(max-width: 991px)').matches) {
      initPerfectScroll();
    }

    $('#mainMenuOpen').on('click touchstart', () => {
      $('body').addClass('navbar-nav-show');
      $('.backdrop').addClass('show');
    });

    $('#mainMenuClose').on('click', () => {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    });

    $('.navbar-menu .with-sub .nav-link').click(function(this: typeof $) {
      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');
  
      if(window.matchMedia('(max-width: 991px)').matches) {
        initPerfectScroll();
        ps.update();
      }
    });

    $('.backdrop').click(function() {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    })

    $('.nav-sub-link').click(function() {
      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').removeClass('navbar-nav-show');
        $('.backdrop').removeClass('show');
      } else {
        $('.nav-item.with-sub').removeClass('show');
      }
    });

  }

  loadFunction() {    

    this.tableService.setFunction("updateStatus", async (val:any) => {
      alert("_______hello")
    })

    this.viewService.setFunction("warrantySendMail", async (val:any) => {
      const rtn = await this.api.post('/email/send/warranty-customer', {data:val, title:"WaiHongBrothers - WARRANTY", token: this.auth.getToken()})

      if(!rtn.status){
        return this.notify.warning(rtn.message)
      }
      this.notify.success("Successful sent.")
    })

    this.tableService.setFunction("viewReceipt", async (val:any) => {
      const url = this.environment.website_url +"/whb/project/receipt/"+ val.uuid;
      window.open(url)
    })
  }

  
}
