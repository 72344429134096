<div class="d-flex align-items-center vh-100">
    <div class="col-12 col-md-4 p-5">
        <form #registrationForm="ngForm" (submit)="registrationForm.valid && submit()" class="sign-wrapper w-100" [ngClass]="{'was-validated': registrationForm.submitted && !registrationForm.valid}">
            <div class="wd-100p">
                <h3 class="tx-color-01 mg-b-5">{{'dashforge.register' | translate}}</h3>
                <p class="tx-color-03 tx-16 mg-b-40">{{'dashforge.register_message' | translate}}</p>
            
                <div class="form-group">
                    <label>{{'dashforge.name' | translate}}</label>
                    <input type="text" class="form-control" name="name" #name="ngModel" [(ngModel)]="data.name" [placeholder]="placeholder.login_username_placeholder" required />
                    <div *ngIf="registrationForm.submitted && name.invalid" class="invalid-feedback">
                        <div *ngIf="name.errors?.['required']">{{'dashforge.name_required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{'dashforge.email' | translate}}</label>
                    <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="data.email" [placeholder]="placeholder.login_username_placeholder" required />
                    <div *ngIf="registrationForm.submitted && email.invalid" class="invalid-feedback">
                        <div *ngIf="email.errors?.['required']">{{'dashforge.email_required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between mg-b-5">
                        <label class="mg-b-0-f">{{'dashforge.password' | translate}}</label>
                    </div>
                    <input type="password" class="form-control" name="password" #password="ngModel" [(ngModel)]="data.password" [placeholder]="placeholder.login_password_placeholder" minlength="6" maxlength="16" required />
                    <div *ngIf="registrationForm.submitted && password.invalid" class="invalid-feedback">
                        <div *ngIf="password.errors?.['required']">{{'dashforge.password_required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between mg-b-5">
                        <label class="mg-b-0-f">{{'dashforge.confirm_password' | translate}}</label>
                    </div>
                    <input type="password" class="form-control" name="confirm_password" #confirm_password="ngModel" [(ngModel)]="data.confirm_password" [placeholder]="placeholder.login_password_placeholder" minlength="6" maxlength="16" required />
                    <div *ngIf="registrationForm.submitted && confirm_password.invalid" class="invalid-feedback">
                        <div *ngIf="password.errors?.['required']">{{'dashforge.password_required' | translate}}</div>
                    </div>
                </div>
                <button class="btn btn-brand-02 btn-block">{{'dashforge.register' | translate}}</button>
            </div>
            <div class="mt-3 d-flex justify-content-between" *ngIf="dsme">
                <a [routerLink]="['/auth/login']">{{'dashforge.sign_in' | translate}}</a>
                <a [routerLink]="['/auth/forgot-password']">{{'dashforge.forgot_password' | translate}}</a>
              </div>
        </form>
    </div>
    <div class="col-md-8 bg-dark vh-100">

    </div>
</div>
