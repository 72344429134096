<div class="form-group">
  <label [for]="fields.mapping.key">{{fields.title}} <em *ngIf="fields.required">*</em>
    <ng-container *ngIf="fields.descp">
      <br>
      <small class="text-muted">{{fields.descp}}</small>
  </ng-container>
  </label>
  <div class="d-flex flex-row">
    <div class="search-dropdown flex-grow-1" [ngClass]="{'open':shown && list !== undefined && list?.length !== 0}">
      <div class="search-dropdown__btn" (click)="show()"><span>{{value}}</span></div>
      <div class="search-dropdown__dropdown">
          <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword" (keyup)="search(keyword)" #input/>
          <input type="hidden" [id]="fields.mapping.key" [name]="fields.mapping.key" [formControl]="ctrl" [required]="fields.required" [value]="mapped_value"/>
          <div class="search-dropdown__dropdown__scroller">
            <div class="search-dropdown__dropdown__item" *ngFor="let item of temp_list" (click)="select(item)">
              {{item[fields.mapping.title]}}
            </div>
            <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0">No Result Found</div>
          </div>
      </div>
    </div>
    <button [hidden]="!fields.mapping.addRequire" class="btn btn-outline-dark add_style" type="button" (click)="add()">
      <b>+</b>
    </button>
  
  </div>
</div>