import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { TableRoutingModule } from './table.routing.module';
import { ButtonComponent } from './button/button.component';
import { FilterComponent } from './filter/filter.component';
import { FilterDateComponent } from './filter-date/filter-date.component';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExportComponent } from './export/export.component';

@NgModule({
  declarations: [
    TableComponent,
    ButtonComponent,
    FilterComponent,
    FilterDateComponent,
    PageNavigationComponent,
    ExportComponent
  ],
  imports: [
    CommonModule,
    TableRoutingModule,
    NgbModule
  ]
})
export class TableModule { }
