import { Component, Inject } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiAuthService } from 'lexi-auth';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';
import { WhbDataService } from 'whb-data';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  
  public config:any = {};
  public data:any = {branch: ""};
  private environment: any;
  public dsme: boolean = false;
  public placeholder:any = {"login_username_placeholder": "Enter Your Email Address", "login_password_placeholder": "Enter Your Password"};
  
  public video:string;

  public branches:any = [];

  constructor(
    private auth: LexiAuthService, 
    private ldt: LexiDashforgeTopService, 
    private api: ApiService,
    private dataService: WhbDataService,
    @Inject("environment") environment: any  
  ) { 
      this.environment = environment;
      this.config = this.ldt.get();

      if(this.environment.login_username_placeholder) {
        this.placeholder.login_username_placeholder = this.environment.login_username_placeholder
      }

      if(this.environment.login_password_placeholder) {
        this.placeholder.login_password_placeholder = this.environment.login_password_placeholder
      }

      if(this.environment.dsme) {
        this.dsme = this.environment.dsme
      }

      let video = ["01", "02", "03", "04", "05"]
      const random = Math.floor(Math.random() * video.length);

      this.video = "https://digitalsme.b-cdn.net/video/my-" + video[random] + ".mp4";
      this.getBranches()
  }

  async getBranches() {
    this.branches = await this.api.post("/setting/get/branch", {})
  }

  async submit() {
    const t = await this.api.post(this.environment.login_url ?? "/users/login", this.data);

    if(t.status) {
      this.api.valueAppender("token", t.token)
      this.auth.setToken(t.token);
      this.auth.update(true)
      this.auth.setUser(t.user);

      this.api.valueAppender("branch_uuid", t.branch_uuid)
      this.dataService.setBranch(t.branch_uuid)

      this.auth.redirect(this.environment.success_login_url ?? ["/post"])
      return;
    }

    alert(t)
  }

}
