import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {

  public branches:any = [];
  public isLoading:boolean = false;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.branches = await this.api.post("/develop/get/user/count", {})
    console.log(this.branches)
  }

  async resendMail() {
    await this.api.post("/email/re-send", {})
  }

  async trackCosting() {
    this.isLoading = true;
    let collections = await this.api.post("/costing/tracker", {})
    console.log(collections)
    this.isLoading = false;
  }
}
