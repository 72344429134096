import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'lib-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {

  public btn: any = {};

  @Input() identifier:any;
  @Input() data:any;

  @Input() set button(val: any) {
    this.btn = val;
    this.btn.title = this.translate.instant(this.btn.title)
  };

  constructor(
    private translate: LexiTranslationHelper, 
    private api: ApiService, 
    private notify: LexiNotifyService, 
    @Inject(DOCUMENT) private document:Document
  ) { }

  ngOnInit(): void {
  }

  async actionClick(type:any) {
        let headers = this.btn.action.headerText; 

    var today = new Date();
    var file = this.identifier + today.getFullYear()+("0" + (today.getMonth() + 1)).slice(-2)+("0" + (today.getDate() + 1)).slice(-2)
    const result = await this.api.post("/table/export", {identifier: this.identifier, header: headers, data: this.data})
    console.log(result)
    
    if(!result.status){
      return this.notify.error("Export data to "+type+" failed.")
    }

    const content = result.content;
    const a = document.createElement('a');
    const blob = new Blob([content], { type: "text/"+type+";charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = file+"."+type;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();        
  }
}
