import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { ViewerService } from '../viewer.service';

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  private identifier:any;
  private layout_uuid:any;
  public params:any = {}
  public rows:any = [];

  @Input() set setFramework(val:any) {
    this.rows = val.framework.structure.layout;
  }

  @Input() set setIdentifier(val:any) {
    this.identifier = val
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public viewerService: ViewerService) { 

    /**
     * The code subscribes to the router events, and when a navigation event, specifically the end of navigation (NavigationEnd), 
     * is detected, it proceeds to handle the route parameters. The code first attempts to retrieve parameters from the snapshot 
     * of the first child route and initializes an empty object called params. If the parameter layout_uuid is absent, it assigns 
     * the entire set of parameters to the params object. Additionally, if layout_data_uuid is present in the parameters, 
     * it updates the params object with this parameter from the child route. The code then captures the parameters of the current 
     * route and updates the params object with the layout_uuid from the current route. Subsequently, it checks if the identifier 
     * is different from the current route's layout_uuid. If so, it updates the identifier and proceeds to fetch data using the 
     * getFramework method from viewerService. If the data retrieval is successful, it updates the rows property with the fetched layout structure.
     */

    this.router.events.subscribe(async (event:Event) => {
      if(event instanceof NavigationEnd) {  
        let params:any = {};
        let route_param:any = this.activatedRoute.firstChild?.snapshot.params;

        if(!route_param || !route_param.layout_uuid) {
          params = route_param;
        }

        if(params.layout_data_uuid) {
          let layout_data_uuid = params.layout_data_uuid
          params = this.activatedRoute.firstChild?.firstChild?.snapshot.params;
          params = {...params, ...{layout_data_uuid}}
        }
        
        let current_route:any = this.activatedRoute.snapshot.params

        this.params = {...params, ...{layout_uuid: current_route.layout_uuid, path: this.activatedRoute.snapshot.url[0].path, module_path: this.activatedRoute.parent!.snapshot.url.map((v:any) => { return v.path})}};

        if(this.identifier || (this.layout_uuid != current_route.layout_uuid)) {
          this.rows = []

          this.layout_uuid = current_route.layout_uuid;
          let y:any = await this.viewerService.getFramework(this.identifier ?? this.layout_uuid, 'layout')  
          if(y.status) {
            this.rows = y.framework.structure.layout
          }
        }
      }
    })
  }

}
