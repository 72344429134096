import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { LexiDashforgeTopModule, LexiDashforgeTopOptions } from 'lexi-dashforge-top';
import { RouterModule } from '@angular/router';
import { LexiTranslationCoreModule, LexiTranslationModule } from 'lexi-translation';
import { TestComponent } from './test/test.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LexiChartV2Component } from './lexi-chart-v2/lexi-chart-v2.component';
import { CUSTOM_COMPONENT } from 'lexi-viewer';
import { customComponent } from './custom-component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SurveyFormComponent } from './pages/survey-form/survey-form.component';
import { FormsModule } from '@angular/forms';
import { DeveloperComponent } from './pages/developer/developer.component';
import { DotComponent } from 'whb-loading';
import { PreviewComponent } from './pages/preview/preview.component';

const config : LexiDashforgeTopOptions = {
  app_title : "Wai Hong&nbsp;<span>Brothers</span>",
  app_desc : "Wai Hong Brothers CRM",
  company_name: "Wai Hong Brothers Sdn Bhd",
  web_url: "https://waihongbrothers.com",
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    SurveyFormComponent,
    DeveloperComponent,
    PreviewComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LexiDashforgeTopModule.forRoot(config),
    LexiTranslationCoreModule,
    NgSelectModule,
    GoogleMapsModule,
    NgMultiSelectDropDownModule.forRoot(),
    LexiChartV2Component,
    DotComponent
  ],
  providers: [
    {provide: "environment", useValue: environment},
    { provide: CUSTOM_COMPONENT, useValue: customComponent }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
