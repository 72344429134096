import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'lib-view-menu',
  templateUrl: './view-menu.component.html',
  styleUrls: ['./view-menu.component.css']
})
export class ViewMenuComponent {

  public groups:any = {};
  public existing_path = "";
  public uuid : string = "";
  public subview:boolean = false

  constructor(private router: Router, private activedRoute: ActivatedRoute, private api: ApiService, private offcanvasService: NgbOffcanvas) { 
    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart && data.snapshot.data) {
        let dt:any = data;
        if(dt.subview) {
          this.subview = dt.subview;
        }
      }
    });
    
    this.activedRoute.params.subscribe(async (v:any) => {
      if (!v.identifier || !v.uuid) {
        this.router.navigate(['/'])
        return;
      }

      this.existing_path = v.identifier
      this.uuid = v.uuid;
      this.groups = await this.api.post("/group/structure", {group_identifier: v.identifier, uuid: v.uuid})
      // console.log('/detail' + '/' + this.existing_path + '/' + this.uuid + '/' + this.groups.menus[0].type + '/' + this.groups.menus[0].path + '/' + this.uuid)
      if(!this.activedRoute.firstChild) {
        var url = '/detail' + '/' + this.existing_path + '/' + this.uuid + '/' + this.groups.menus[0].type + '/' + this.groups.menus[0].path + '/' + this.uuid
        this.router.navigate([url])         
      }
    })
    
  }

  getCurrentRoutes() {
    return this.router.url;
  }

  getLink(menu:any) {
    return ['/detail', this.existing_path, this.uuid, menu.type, menu.path, this.uuid]    
  }


  async viewMenu() {
    if(this.offcanvasService.hasOpenOffcanvas()){
      this.offcanvasService.dismiss()
    }

    let canvas = this.offcanvasService.open(MenuComponent, {position: "end", backdropClass: "custom-canvas-backdrop", panelClass: "bg-light table-menu-mobile", scroll: true,}) 
    canvas.componentInstance.data = this.groups;
    canvas.componentInstance.existing_path = this.existing_path;
    canvas.componentInstance.uuid = this.uuid;
    canvas.componentInstance.close.subscribe(async (val:any) => {
      canvas.close()  
    })
  }
}
