import { Injectable, Inject, InjectionToken } from '@angular/core';

export const CUSTOM_COMPONENT = new InjectionToken<() => void>('Custom Component');

@Injectable({
  providedIn: 'root'
})

export class CustomService {
  constructor(@Inject(CUSTOM_COMPONENT) private customComponent: (v:any) => void) {}

  async invokeCustomComponent(v:any) {
    return await this.customComponent(v);
  }
}
