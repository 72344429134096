import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent {

  @Input() set setFilters (val:any) { 
    if(val){
      this.filters = val;
    }
  }
  @Output() close = new EventEmitter();

  public filters:any;
  public data:any = {sequence: {}, date: {}};
  constructor() { }

  submit(){
    this.close.emit(this.data)
  }

  reset() {
    this.close.emit("reset")
  }
  
  closeModal() {
    this.close.emit(false)
  }
}
