import { ChangeDetectorRef, Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

@Component({
  selector: 'lib-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements AfterViewInit {
  
  public identifier:any;
  public param_2:any;
  public current_framework_uuid:any;
  public parent_path:string = "/";
  private load = false;

  @ViewChild("menuViewer", {read: ViewContainerRef}) menuView!: ViewContainerRef;

  constructor(private activatedRoute:ActivatedRoute, private cdr: ChangeDetectorRef, private router: Router) {

    let parent_paths = this.activatedRoute.parent?.snapshot.url;
    let parent_path:any = [];

    parent_paths?.forEach((v:UrlSegment) => {
      parent_path.push(v.path)
    })
    
    if(parent_path.length > 0) {
      this.parent_path += parent_path.join("/")
    }
 
    this.activatedRoute.params.subscribe((v:any) => {
      if(this.identifier != v.param_1) {
        this.identifier = v.param_1;
        this.param_2 = v.param_2;
        this.init(this.identifier, 'menu')  
      }
    })

    this.activatedRoute.firstChild?.params.subscribe((v:any) => {
      this.current_framework_uuid = v.param_3
    })
  }

  ngAfterViewInit(): void {
    this.load = true;
    this.cdr.detectChanges();
    this.init(this.identifier, 'menu');
  }

  async init(identifier:string, type:string) {

    if (!this.load) {
      return;
    }

    this.menuView.clear();
     
    // let menuComponent = ((await import("lexi-menu")).LexiMenuComponent);
    // let menuComponentInstance = this.menuView.createComponent(menuComponent);
    // menuComponentInstance.instance.identifier = identifier;
    // menuComponentInstance.instance.param_2 = this.param_2;
    // menuComponentInstance.instance.setViewIdentifier = identifier;

   // menuComponentInstance.instance.viewerType = "horizontal";
//    menuComponentInstance.instance.parent_path = this.parent_path
 //   menuComponentInstance.instance.current_framework_uuid = this.current_framework_uuid
  }

}
