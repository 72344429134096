import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-vertical-warranty',
  templateUrl: './vertical-warranty.component.html',
  styleUrls: ['./vertical-warranty.component.css']
})
export class VerticalWarrantyComponent {

  public ctrl:any;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    this.ctrl.type = 'hidden'
    if(val.value) {
      this.warranties = val.value;
    } else {
      this.ctrl.value = this.warranties
    }
  }

  public warranties : any = [{}];
  
  constructor() { }

  addRow() {
    this.warranties.push({})
  }

  removeRow(index:any) {
    if(this.warranties.length <= 1) {
      return;
    }

    this.warranties.splice(index, 1)
  }

  onchange() {
    this.ctrl.value = this.warranties
  }
}
