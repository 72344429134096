import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ViewerContentComponent } from './viewer/viewer-content/viewer-content.component';
// import { ViewerComponent } from './viewer/viewer.component';
import { LayoutComponent } from './layout/layout.component';
import { BridgeComponent } from './layout/bridge/bridge.component';

const routes: Routes = [

  { path: "layout/:layout_uuid", component: LayoutComponent, children: [
    { path: "data/:layout_data_uuid", component: BridgeComponent, children: [
      { path: "", pathMatch: 'full', component: BridgeComponent },
      { path: ":framework_type", component: BridgeComponent },
      { path: ":framework_type/:framework_uuid", component: BridgeComponent },
      { path: ":framework_type/:framework_uuid/:data_uuid", component: BridgeComponent }]
    },
    { path: "", pathMatch: 'full', component: BridgeComponent },
    { path: ":framework_type", component: BridgeComponent },
    { path: ":framework_type/:framework_uuid", component: BridgeComponent },
    { path: ":framework_type/:framework_uuid/:data_uuid", component: BridgeComponent }
  ]},

  
  // { path: ":param_1/:param_2", component: ViewerComponent, children: [
  //  { path: ":param_1/:param_2", component: ViewerContentComponent }, // remove to prevent crash with top 
  //   { path: ":param_1/:param_2/:param_3", component: ViewerContentComponent }
  // ]},
  // { path: ":param_1", component: ViewerComponent, children: [
  //   { path: ":param_1/:param_2", component: ViewerContentComponent },
  //   { path: ":param_1/:param_2/:param_3", component: ViewerContentComponent },
  //   { path: ":param_1/:param_2/:param_3/:param_4", component: ViewerContentComponent }
  //   // this cant hit beause there is total 4 param from parent. need to revamp on this.
  // ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LexiViewerRoutingModule { }
