<div class="mb-3">
    <p class="mb-0"><small><b>{{field.title}}</b></small></p>

    <table class="table table-sm">
        <tr>
            <th>Item</th>
            <th>Value (RM)</th>
            <th>Warranty (Month)</th>
        </tr>
        <tr *ngFor="let warranty of data[field.mapping.key]; let i = index">
            <td>{{warranty.title}}</td>
            <td>{{warranty.value}}</td>
            <td>{{warranty.month}}</td>
        </tr>
    </table>
</div>