import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BarChartComponent, ScatterComponent } from 'lexi-chart-v2';

@Component({
  selector: 'app-lexi-chart-v2',
  templateUrl: './lexi-chart-v2.component.html',
  styleUrls: ['./lexi-chart-v2.component.scss'],
  standalone: true,
  imports: [BarChartComponent, ScatterComponent, CommonModule, FormsModule]
})

export class LexiChartV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public data: any = {
    /**
     * {
        label: 'My First dataset111',
        fill: false,
        // tension: 0.4,
        // backgroundColor: 'rgba(75,192,192,0.4)',
        // borderColor: 'rgba(75,192,192,1)',
        // borderCapStyle: 'butt',
        // borderDash: [],
        // borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        // pointBorderColor: 'rgba(75,192,192,1)',
        // pointBackgroundColor: '#fff',
        // pointBorderWidth: 1,
        // pointHoverRadius: 5,
        // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        // pointHoverBorderColor: 'rgba(220,220,220,1)',
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40],
      }, 
     */
    values:
      [{
        yAxisID: 'y1',
        label: 'My First dataset222',
        data: [Math.random() * 80, Math.random() * 80, 80, 81, Math.random() * 80, 55, 40],
      }], labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
  }

  public scatterData: any = {
    dataset: [{
      label: "Dataset1",
      backgroundColor: 'red',
      data: [
        { x: 50, y: 7 },
        { x: 60, y: 8 },
        { x: 70, y: 8 },
        { x: 80, y: 9 },
        { x: 90, y: 9 },
        { x: 100, y: 9 },
        { x: 110, y: 10 },
        { x: 120, y: 11 },
        { x: 130, y: 14 },
        { x: 140, y: 14 },
        { x: 150, y: 15 }
      ],
    }, {
      label: "Dataset2",
      backgroundColor: 'yellow',
      data: [
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
        { x: (Math.random() * 100) + 40, y: Math.random() * 15 }
      ],
    }]
  };

  public typeSelector: any = "line";
  public fillCheck: any;
  chartTitle: any = "Set Chart Title";
  yTitle = "y-axis";
  xTitle = "x-axis";
  dispLegend = true;
  dispAxis = false;
  dispTitle = true;
  resetOptions: any;
  tension: any = 0.3;
  updateData: any;
  chartType = "line";

  public chartOptions: any = {
    indexAxis: "x",
    responsive: true,
    lineTension: 0.4,
    plugins: {
      title: {
        display: this.dispTitle,
        text: this.chartTitle
      },
      legend: {
        display: this.dispLegend
      }
    }
  };

  setType() {
    if (this.typeSelector == "pie" || this.typeSelector == "doughnut") {
      this.updatePieDoughnutOptions();
    } else {
      this.barHorizontal = false;
      this.chartOptions.indexAxis = "x";
      this.updateOption();
    }

    this.chartType = this.typeSelector;
  }

  barHorizontal = false;
  setHorizontal() {
    setTimeout(() => {
      if (this.barHorizontal) {
        this.chartOptions.indexAxis = "y";
      } else {
        this.chartOptions.indexAxis = "x";
      }
      this.updateOption();
    }, 1);
  }

  setFill() {
    let no = this.data.values.length;
    for (let index = 0; index < no; index++) {
      this.data.values[index].fill = this.fillCheck;
    }
    this.updateOption();
  }

  addNewData() {
    // add new bar chart data
    this.updateData = {
      labels: ['January', 'February'],
      data: [[Math.random() * 80, Math.random() * 80], [Math.random() * 80, Math.random() * 80]]
    }
  }

  test() {
    this.data = {
      values:
        [{
          label: 'My First dataset11222444',
          fill: false,
          data: [Math.random() * 80, Math.random() * 80, 80, 81, Math.random() * 80, 55, 40],
        }, {
          label: 'My First dataset11222333',
          fill: false,
          data: [Math.random() * 80, Math.random() * 80, 80, 81, Math.random() * 80, 55, 40],
        }],
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
    }

    this.scatterData = {
      dataset: [{
        label: "Dataset1",
        backgroundColor: 'red',
        data: [
          { x: 50, y: 7 },
          { x: 60, y: 8 },
          { x: 70, y: 8 },
          { x: 80, y: 9 },
          { x: 90, y: 9 },
          { x: 100, y: 9 },
          { x: 110, y: 10 },
          { x: 120, y: 11 },
          { x: 130, y: 14 },
          { x: 140, y: 14 },
          { x: 150, y: 15 }
        ],
      }, {
        label: "Dataset2",
        backgroundColor: 'yellow',
        data: [
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
          { x: (Math.random() * 100) + 40, y: Math.random() * 15 }
        ],
      }]
    };

  }

  updateOption() {
    this.resetOptions = {
      indexAxis: this.chartOptions.indexAxis,
      responsive: this.chartOptions.responsive,
      lineTension: this.tension,
      plugins: {
        title: {
          display: this.dispTitle,
          text: this.chartTitle
        },

        legend: {
          display: this.dispLegend
        },
      },

      scales: {
        y: {
          title: {
            display: this.dispAxis,
            text: this.yTitle
          }
        },

        x: {
          title: {
            display: this.dispAxis,
            text: this.xTitle
          }
        }
      }
    };
  }

  updatePieDoughnutOptions() {
    this.resetOptions = {
      responsive: this.chartOptions.responsive,
      plugins: {
        title: {
          display: this.dispTitle,
          text: this.chartTitle
        },

        legend: {
          display: this.dispLegend
        },
      }
    };
  }
}

