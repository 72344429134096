import { Component, AfterViewInit, ViewContainerRef, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormComponent implements AfterViewInit {
  
  public isLoading:boolean = true;

  public innerPart:any;
  public form: FormGroup;
  public form_option: any = {method : "normal", wrapperClass: "col-12 col-md-4"}
  public presetToken = false;
  public inputFormOption:boolean = false;
  
  @ViewChild("wrapper", {read: ViewContainerRef}) wrapper!: ViewContainerRef;

  @Input() loadingPath:any;
  @Input() submissionPath:any;
  @Input() submitOnChange: boolean = false
  @Input() hideTitle:boolean = false;
  @Input() hideButton:boolean = false;
  
  @Input() set setFormOption(form_option:any) {
    if(form_option) {
      this.inputFormOption = true;
      this.form_option = {...this.form_option, ...form_option};
    }
  }

  @Input() set setFormIdentifier(val:any) {
    if(val) {
      this.form_identifier = val;
      this.loadForm()
    }
  };

  @Input() set setWrapper(vcr: ViewContainerRef) {
    this.wrapper = vcr;
  }

  @Input() set setStructure(val:any) {
    this.structure = val;
    this.customLoadForm();
  } 

  @Input() set setData(val:any) {
    this.data = val
  } 

  @Output() complete = new EventEmitter<any>()
  @Output() customSubmit = new EventEmitter<any>()
  @Output() formLoading = new EventEmitter<any>()

  private form_identifier: any;
  private data_uuid: any;

  public loading: boolean = true;
  public structure : any = {};
  public subview:boolean = false;
  public subview_identifier:any;
  public subview_uuid:any;
  public token:any;
  public data:any = {};

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, private cdr: ChangeDetectorRef, public translate: LexiTranslationHelper, private renderer: Renderer2 ) {
    this.form = new FormGroup({});

    let snapshot:any = activatedRoute.snapshot.data;
    if(snapshot.subview) {
      this.subview = snapshot.subview;
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    this.activatedRoute.params.subscribe(async (v: any) => {
      if(v.form_identifier && (!this.form_identifier || v.form_identifier !== this.form_identifier)){
        this.form_identifier = v.form_identifier;
        this.data_uuid = v.uuid;
        this.loadForm()
      }
    })    
  }

  ngAfterViewInit(): void {
    this.isLoading = true
    this.cdr.detectChanges();
  }

  async customLoadForm() {
    await this.generateFormControls();
    await this.build();
  }

  async loadForm() {
    this.loading = true;
    this.structure = {}
    let data:any = {form_identifier: this.form_identifier, data_uuid: this.data_uuid}
  
    if(this.subview_identifier && this.subview_uuid){
      data['filter'] = {};
      data['filter']['identifier'] = this.subview_identifier;
      data['filter']['identifier_uuid'] = this.subview_uuid;
    }

    let structure = await this.api.post(this.loadingPath ?? "/form/structure", data)
    this.loading = false;

    if(!structure.status && structure.redirect) {
      this.router.navigate([structure.redirect])
      return;
    }

    if(!structure.status) {
      return;
    }

    this.structure = structure.structure;    
    if(this.structure.width && !this.inputFormOption) {
      this.form_option.wrapperClass = this.structure.width 
    }

    if(Object.keys(this.data).length > 0){
      this.structure.data = this.data;
    }

    if(this.structure.fields.length > 0) {
      this.generateFormControls();
      await this.build();
    }
    
    this.formLoading.emit(this.loading)
  }

  generateFormControls() {
    let formFields : any = {};

    for(var x = 0; x < this.structure.fields.length; x++) {
      let field = this.structure.fields[x];

      if(['text-only'].indexOf(field.type) > -1) {
        continue;
      }
      
      let value = this.structure.data[field.mapping.key] || this.structure.data[field.mapping.key] == 0 ? this.structure.data[field.mapping.key] : "";

      let fv:any = value;
      if(field.type == 'readonly') {
        fv = {};
        fv.value = value
        fv.disabled = true;
      } 

      formFields[field.mapping.key] = new FormControl(fv, this.validatorBuilder(field))
    }

    if(this.structure.data && this.structure.data.id) {
      formFields['id'] = new FormControl(this.structure.data['id'], [Validators.required])
    }
    
    if(this.structure.data && this.structure.data.uuid) {
      formFields['uuid'] = new FormControl(this.structure.data['uuid'], [Validators.required])
    }

    if(this.form_identifier) {
      formFields['form_identifier'] = new FormControl(this.form_identifier, [Validators.required])
    }

    this.form = new FormGroup(formFields);

    this.cdr.detectChanges();
  }

  validatorBuilder(field: any) {
    let validator:any = [];

    if(field.required) {
      validator.push(Validators.required)
    }

    return validator;
  }

  async build() {
    this.wrapper.clear();

    let component:any;
    let componentInstance:any;
    let cacheComponentInstace:any = {}
    let autoComponentInstace:any = {}
    let rendererWrapper = this.renderer;
    
    for(var x = 0; x < this.structure.fields.length; x++) {

      let field = this.structure.fields[x];

      if(field.mapping && field.mapping.hide_by_filter && this.subview_uuid) {
        continue;
      }

      field.title = this.translate.instant(field.title)

      switch(field.type) {

        case "autocomplete":
          let autoCompleteComponent:any = ((await import("../component/autocomplete/autocomplete.component")).AutocompleteComponent);
          let autoCompleteComponentInstance:any = this.wrapper.createComponent(autoCompleteComponent);
          autoCompleteComponentInstance.instance.control = this.form.controls[field.mapping.key];
          autoCompleteComponentInstance.instance.setField = field

          cacheComponentInstace[field.mapping.key] = autoCompleteComponentInstance  

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(autoCompleteComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(autoCompleteComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(autoCompleteComponentInstance.location.nativeElement, "d-none")
              }
            })
          }

          autoCompleteComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            this.form.value[val.key] = val.val;

            if(!val || !val.togglers) {
              return;
            }
            
            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]] && cacheComponentInstace[val.togglers[x]].instance) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated = this.form.value[val.key]
              }
            }
          });
        break;

        case "select":
          let selectComponent:any = ((await import("../component/select/select.component")).SelectComponent);
          let selectComponentInstance:any = this.wrapper.createComponent(selectComponent);

          selectComponentInstance.instance.field = field

          selectComponentInstance.instance.control = this.form.controls[field.mapping.key];

          cacheComponentInstace[field.mapping.key] = selectComponentInstance

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(selectComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(selectComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(selectComponentInstance.location.nativeElement, "d-none")
              }
            })
          }

          selectComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]] && cacheComponentInstace[val.togglers[x]].instance) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated = this.form.value[val.key]
              }  
            }
          });

        break;

        case "multi-select":
          let mSelectComponent = ((await import("../component/multi-select/multi-select.component")).MultiSelectComponent);
          let mSelectComponentInstance:any = this.wrapper.createComponent(mSelectComponent);

          mSelectComponentInstance.instance.field = field

          mSelectComponentInstance.instance.control = this.form.controls[field.mapping.key];

          cacheComponentInstace[field.mapping.key] = mSelectComponentInstance

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(mSelectComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(mSelectComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(mSelectComponentInstance.location.nativeElement, "d-none")
              }
            })
          }

          mSelectComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]] && cacheComponentInstace[val.togglers[x]].instance) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated = this.form.value[val.key]
              }  
            }
          });

        break;

        case "multi-select-checkbox":
          let mSelectCheckboxComponent:any = ((await import("../component/multi-select-checkbox/multi-select-checkbox.component")).MultiSelectCheckboxComponent);
          let mSelectCheckboxComponentInstance:any = this.wrapper.createComponent(mSelectCheckboxComponent);
          mSelectCheckboxComponentInstance.instance.control = this.form.controls[field.mapping.key];
          mSelectCheckboxComponentInstance.instance.field = field

          cacheComponentInstace[field.mapping.key] = mSelectCheckboxComponentInstance

          mSelectCheckboxComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            this.form.value[field.mapping.key] = val
          });

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(mSelectCheckboxComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(mSelectCheckboxComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(mSelectCheckboxComponentInstance.location.nativeElement, "d-none")
              }
            })
          }

        break;

        case "status":
          let statusComponent:any = ((await import("../component/status/status.component")).StatusComponent);
          let statusComponentInstance:any = this.wrapper.createComponent(statusComponent);
          statusComponentInstance.instance.control = this.form.controls[field.mapping.key];
          statusComponentInstance.instance.field = field;

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(statusComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(statusComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(statusComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "yesno":
          let yesnoComponent:any = ((await import("../component/yesno/yesno.component")).YesnoComponent);
          let yesnoComponentInstance:any = this.wrapper.createComponent(yesnoComponent);
          yesnoComponentInstance.instance.control = this.form.controls[field.mapping.key];
          yesnoComponentInstance.instance.field = field;

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(yesnoComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(yesnoComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(yesnoComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "wysiwyg":
          let wysiwygComponent:any = ((await import("../component/wysiwyg/wysiwyg.component")).WysiwygComponent);
          let wysiwygComponentInstance:any = this.wrapper.createComponent(wysiwygComponent);
          wysiwygComponentInstance.instance.field = field
          wysiwygComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(wysiwygComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(wysiwygComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(wysiwygComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "textarea":
          let textareaComponents:any = ((await import("../component/textarea/textarea.component")).TextareaComponent);
          let textareaComponentInstance:any = this.wrapper.createComponent(textareaComponents);
          textareaComponentInstance.instance.field = field
          textareaComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(textareaComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(textareaComponentInstance.location.nativeElement, "d-none")
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(textareaComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "map":
          let mapComponent:any = ((await import("../component/map/map.component")).MapComponent);
          let mapComponentInstance:any = this.wrapper.createComponent(mapComponent);
          mapComponentInstance.instance.field = field
          mapComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(mapComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(mapComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(mapComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "date":   
        
          if(this.form.controls[field.mapping.key].value) {
            var date = new Date(this.form.controls[field.mapping.key].value);
            this.form.controls[field.mapping.key].setValue( date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + (date.getDate())).slice(-2) );
          }else{
            var todayDate = new Date();
            this.form.controls[field.mapping.key].setValue( todayDate.getFullYear()+"-"+("0" + (todayDate.getMonth() + 1)).slice(-2)+"-"+("0" + (todayDate.getDate())).slice(-2) );   
          }

          let dateComponent:any = ((await import("../component/text/text.component")).TextComponent);
          let dateComponentInstance:any = this.wrapper.createComponent(dateComponent);
          dateComponentInstance.instance.field = field
          dateComponentInstance.instance.control = this.form.controls[field.mapping.key];

          cacheComponentInstace[field.mapping.key] = dateComponentInstance;

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(dateComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(dateComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(dateComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "time":   
        
          if(this.form.controls[field.mapping.key].value) {
            // var date = new Date(this.form.controls[field.mapping.key].value);
            // this.form.controls[field.mapping.key].setValue( date.getHours()+":"+("0" + (date.getMinutes() + 1)).slice(-2) );
          }else{
            var todayDate = new Date();
            this.form.controls[field.mapping.key].setValue( todayDate.getHours()+":"+("0" + todayDate.getMinutes()).slice(-2) );   
          }

          let timeComponent:any = ((await import("../component/text/text.component")).TextComponent);
          let timeComponentInstance:any = this.wrapper.createComponent(timeComponent);
          timeComponentInstance.instance.field = field
          timeComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(timeComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(timeComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(timeComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "import-export":
          component = ((await import("../component/import-export/import-export.component")).ImportExportComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          
        break;

        case "text":
        case "email":
        case "number":
        case "readonly":
        case "password":
          let textComponent:any = ((await import("../component/text/text.component")).TextComponent);
          let textComponentInstance:any = this.wrapper.createComponent(textComponent);
          textComponentInstance.instance.field = field
          textComponentInstance.instance.control = this.form.controls[field.mapping.key];

          cacheComponentInstace[field.mapping.key] = textComponentInstance

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(textComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(textComponentInstance.location.nativeElement, "d-none")
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(textComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "text-only":
          let textOnlyComponent:any = ((await import("../component/text-only/text-only.component")).TextOnlyComponent);
          let textOnlycomponentInstance:any = this.wrapper.createComponent(textOnlyComponent);
          textOnlycomponentInstance.instance.field = field

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(textOnlycomponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(textOnlycomponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(textOnlycomponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "checkbox":
          let checkboxComponent:any = ((await import("../component/checkbox/checkbox.component")).CheckboxComponent);
          let checkboxComponentInstance:any = this.wrapper.createComponent(checkboxComponent);
          checkboxComponentInstance.instance.field = field
          checkboxComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(checkboxComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(checkboxComponentInstance.location.nativeElement, "d-none")
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(checkboxComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;
        
        case "multi-checkbox":
          let mCheckboxComponent:any = ((await import("../component/multi-checkbox/multi-checkbox.component")).MultiCheckboxComponent);
          let mCheckboxComponentInstance:any = this.wrapper.createComponent(mCheckboxComponent);
          mCheckboxComponentInstance.instance.field = field
          mCheckboxComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(mCheckboxComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(mCheckboxComponentInstance.location.nativeElement, "d-none")
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(mCheckboxComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "vertical-warranty":
          let verticalWarrantyComponent:any = ((await import("../component/vertical-warranty/vertical-warranty.component")).VerticalWarrantyComponent);
          let verticalWarrantyComponentInstance:any = this.wrapper.createComponent(verticalWarrantyComponent);
          verticalWarrantyComponentInstance.instance.field = field
          verticalWarrantyComponentInstance.instance.control = this.form.controls[field.mapping.key];


          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(verticalWarrantyComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(verticalWarrantyComponentInstance.location.nativeElement, "d-none")
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(verticalWarrantyComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "radio":
          let radioComponent:any = ((await import("../component/radio/radio.component")).RadioComponent);
          let radioComponentInstance:any = this.wrapper.createComponent(radioComponent);
          radioComponentInstance.instance.control = this.form.controls[field.mapping.key];
          radioComponentInstance.instance.field = field

          cacheComponentInstace[field.mapping.key] = radioComponentInstance

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(radioComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(radioComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(radioComponentInstance.location.nativeElement, "d-none")
              }
            })
          }

          radioComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]] && cacheComponentInstace[val.togglers[x]].instance) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated = this.form.value[val.key]
              }  
            }
          });
        break;

        case "file":
            let fileComponent:any = ((await import("../component/file-uploader/file-uploader.component")).FileUploaderComponent);
            let fileComponentInstance:any = this.wrapper.createComponent(fileComponent);
            fileComponentInstance.instance.field = field
            fileComponentInstance.instance.control = this.form.controls[field.mapping.key];

            if(field.mapping.conditional_toggle){
              rendererWrapper.addClass(fileComponentInstance.location.nativeElement, "d-none")
  
              this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
  
                if(val && field.mapping.conditional_toggle.val !== val){
                  rendererWrapper.addClass(fileComponentInstance.location.nativeElement, "d-none")
                  
                }else if (field.mapping.conditional_toggle.val == val){
                  rendererWrapper.removeClass(fileComponentInstance.location.nativeElement, "d-none")
                }
              })
            }
        break;

        case "dropzone":
            alert("Dropzone not available.")
        break;

        case "mobile":
            let mobileComponent:any = ((await import("../component/mobile/mobile.component")).MobileComponent);
            let mobileComponentInstance:any = this.wrapper.createComponent(mobileComponent);
            mobileComponentInstance.instance.field = field
            mobileComponentInstance.instance.control = this.form.controls[field.mapping.key];

            if(field.mapping.conditional_toggle){
              rendererWrapper.addClass(mobileComponentInstance.location.nativeElement, "d-none")
  
              this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {
  
                if(val && field.mapping.conditional_toggle.val !== val){
                  rendererWrapper.addClass(mobileComponentInstance.location.nativeElement, "d-none")
                  
                }else if (field.mapping.conditional_toggle.val == val){
                  rendererWrapper.removeClass(mobileComponentInstance.location.nativeElement, "d-none")
                }
              })
            }
        break;
       
        case "label":
          let labelComponent:any = ((await import("../component/label/label.component")).LabelComponent);
          let labelComponentInstance:any = this.wrapper.createComponent(labelComponent);
          labelComponentInstance.instance.field = field
          labelComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(labelComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(labelComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(labelComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;
        
        case "media":
          let mediaComponent:any = ((await import("../component/media/media.component")).MediaComponent);
          let mediaComponentInstance:any = this.wrapper.createComponent(mediaComponent);

          mediaComponentInstance.instance.field = field
          mediaComponentInstance.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(mediaComponentInstance.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(mediaComponentInstance.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(mediaComponentInstance.location.nativeElement, "d-none")
              }
            })
          }
        break;

        case "accordion":
          if(!this.form.controls[field.mapping.key].value){
            this.form.controls[field.mapping.key].setValue([])
          }

          let accordionComponent:any = ((await import("../component/accordion/accordion.component")).AccordionComponent);
          let accordionInstant:any = this.wrapper.createComponent(accordionComponent);
          accordionInstant.instance.field = field
          accordionInstant.instance.control = this.form.controls[field.mapping.key];

          if(field.mapping.conditional_toggle){
            rendererWrapper.addClass(accordionInstant.location.nativeElement, "d-none")

            this.form.get(field.mapping.conditional_toggle.key)?.valueChanges.subscribe((val:any) => {

              if(val && field.mapping.conditional_toggle.val !== val){
                rendererWrapper.addClass(accordionInstant.location.nativeElement, "d-none")
                
              }else if (field.mapping.conditional_toggle.val == val){
                rendererWrapper.removeClass(accordionInstant.location.nativeElement, "d-none")
              }
            })
          }

        break;
        
        case "table":
          let formTableComponent:any = ((await import("../component/table/table.component")).TableComponent);
          let formTableComponentInstance:any = this.wrapper.createComponent(formTableComponent);
          formTableComponentInstance.instance.field = field
          formTableComponentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "multi-text":
          let multiTextComponent:any = ((await import("../component/multi-text/multi-text.component")).MultiTextComponent);
          let multiTextComponentInstance:any = this.wrapper.createComponent(multiTextComponent);
          multiTextComponentInstance.instance.field = field
          multiTextComponentInstance.instance.control = this.form.controls[field.mapping.key];
        break;
        default:
          console.warn("Undefined Filed Type", field.type)
        break;
      }

    };

    if(this.structure.data && this.structure.data.uuid) {
      component = ((await import("../component/hidden/hidden.component")).HiddenComponent);
      componentInstance = this.wrapper.createComponent(component);
      componentInstance.instance.data = this.structure.data['uuid'];
      componentInstance.instance.field = {mapping : {key: "uuid"}}
    }

    this.cdr.detectChanges();
    if(this.submitOnChange){
      this.form.valueChanges.subscribe(v => {
        this.customSubmit.emit(v)
      })
    }
  }

  async submit(event:any){    
    this.loading = true;

    if(this.form.status != "VALID") {
      alert("Invalid Entry. Please check input again")
      this.loading = false;
      return;
    }

    var data:any = {};
        data['form'] = this.form.value;
        data['filter'] = {}
    if(this.subview_identifier && this.subview_uuid){
      data['filter']['identifier'] = this.subview_identifier;
      data['filter']['identifier_uuid'] = this.subview_uuid;
    }
    
    if(localStorage.getItem("profile")) {
      data['profile_uuid'] = localStorage.getItem("profile");
    }

    let rtn = await this.api.post(this.submissionPath ?? "/form/submit", data)

    if(rtn.status) {
      switch(this.form_option.method) {
        case "popup":
          var data:any = this.form.value;
          data.id = rtn.insertId
          this.complete.emit(data)
        break;

        case "nav":
          this.complete.emit(true)
        break;

        default:
          if(this.structure.redirect && this.structure.redirect_subwiew && rtn.uuid){
            this.router.navigate([this.structure.redirect+"/"+rtn.uuid+"/"+this.structure.redirect_subwiew+"/"+rtn.uuid])
            return
          }
          
          if(this.structure.redirect) {
            this.redirect();
          }
        break;
      }
    } else if(rtn.message) {
      alert(rtn.message)
    }

    this.loading = false;
  }
 
  redirect() {
    if(this.form_option.method == "popup") {
      this.complete.emit(false)
      return;
    }

    if(!this.subview) {
      this.router.navigate([this.structure.redirect])
    } else if(this.subview && this.structure.subview_off == 0){
      this.router.navigate(['/detail/'+this.subview_identifier +'/'+ this.subview_uuid +'/list/'+ this.form_identifier +"/"+ this.data_uuid])      
    } else {
      alert('Data update successful.')
    }
  }
}