import { Component,  Input, HostListener,  ElementRef,  ViewChild, Output, EventEmitter } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { find } from "rxjs/operators";
import { FormComponent } from "../../form/form.component";

@Component({
  selector: 'lib-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent {

  public fields:any;
  public options:any = [];

  @Input() set setField(val:any) {

    this.fields = val;
    this.list = this.fields.options;
    this.temp_list = this.fields.options;
    this.initValue();
  };

  @Input() set setRelated(val:any){
    if(!val){
      return;
    }

    this.temp_list = [];
    this.temp_list = this.fields.options.filter((item:any) => {
        return item['related'] == val;
    });

    this.list = this.temp_list;
    if(!this.temp_list || this.temp_list.length == 0){
      this.value = 'No data found'
    }else {
      this.value = 'Select'
    }
  };

  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }

  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  public ctrl:any;
  // public field: any = {};
  public list:any = [];
  public temp_list:any;
  public keyword = "";
  public mapped_value :any;
  public value: any = "Select";
  public shown = false;

  @ViewChild("input", { static: false }) input!: ElementRef;
 
  constructor(private ele: ElementRef, private ngbModal: NgbModal) {}
  
  search(e:any) {
    if(!e || e == undefined) {
      this.temp_list = this.list;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.list.filter((x:any) => {
      if(x[this.fields.mapping.title]){
        if (x[this.fields.mapping.title].toLowerCase().indexOf(val) !== -1 || !val) {
          return x;
        }
      }
    });
    this.temp_list = temp;
  }

  searchByValue(e:any) {

    if(!this.list || this.list.length == 0) {
      return false;
    }

    const find = this.list.find((x:any) => {
      return x.val == e
    });

    return find;
  }

  initValue() {
    let val = this.searchByValue(this.ctrl.value)

    if(!val) {
      return;
    }

    this.select(val)
  }

  select(item:any) {
    this.keyword = item[this.fields.mapping.title]
    this.value = item[this.fields.mapping.title];
    this.mapped_value = item.val

    this.ctrl.setValue(item.val)

    let d:any = {key: this.fields.mapping.key ? this.fields.mapping.key : this.keyword, togglers: this.fields.mapping.related_toggler ? this.fields.mapping.related_toggler :'', val: this.mapped_value}
    this.outputEvent.emit(d) 

    this.shown = false;
  }
  
  show() {
    this.shown = !this.shown;
    this.keyword = "";

    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }

  @HostListener("document:click", ["$event"]) onClick(e:any) {
    if (!this.ele.nativeElement.contains(e.target) && this.shown) {
      this.shown = false;
    }
  }

  // detectChanges($e:any) {
  //   console.log('_______', this.fields)
  //   let d:any = {key: this.fields.mapping.key, togglers: this.fields.mapping.related_toggler}
  //   this.outputEvent.emit(d)
  // }

  add() {
  if(this.fields.mapping.form_identifier) {
    let modal = this.ngbModal.open(FormComponent, {size:'lg', modalDialogClass:'lexi-modal'})
    modal.componentInstance.setFormIdentifier = this.fields.mapping.form_identifier;
    modal.componentInstance.setFormOption = {method: "popup", wrapperClass:"col-12 col-md-12"};
    modal.componentInstance.complete.subscribe((data:any) => {
      if(!data) {
        modal.close();
        return;
      }
      
      let title = '';
      if(!this.fields.mapping.title_identifier) {
        console.error("Title identifier Not found.")
      }

      for(let x = 0; x < this.fields.mapping.title_identifier.length; x++){
        title += data[this.fields.mapping.title_identifier[x]]
        if(x < this.fields.mapping.title_identifier.length - 1){
          title += '-'
        }
      }

      this.fields.options.push({val: data[this.fields.mapping.val], title: title})
      this.list = this.fields.options;
      this.temp_list = this.fields.options;
      modal.close()

      this.select({val:data[this.fields.mapping.val], title:title})
    })
   } else {
    console.error("Form identifier not found.")
   }
  }
}