<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <div class="row mt-1">
        <div class="col"><label class="ms-3">Row</label></div>
        <div class="col">
            <div class="d-flex flex-row justify-content-end">
                <button type="button" class="btn btn-outline-dark btn-sm me-1" (click)="addTableRow()">+</button>
                <button type="button" class="btn btn-outline-dark btn-sm" (click)="removeTableRow()">-</button>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col"><label class="ms-3">Column</label></div>
        <div class="col">
            <div class="d-flex flex-row justify-content-end">
                <button type="button" class="btn btn-outline-dark btn-sm me-1" (click)="addColumns()">+</button>
                <button type="button" class="btn btn-outline-dark btn-sm" (click)="removeColumns()">-</button>
            </div>
        </div>
    </div>
    <table class="table mt-2">
        <tbody>
            <tr *ngFor="let dt of data; let i = index; trackBy: trackByFn">
                <td class="p-0" *ngFor="let d of dt; let idx = index; trackBy: trackByFn">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="d.col" [name]="'row'+ i +'_col'+ idx">
                </td>
            </tr>
        </tbody>
    </table>
</div>