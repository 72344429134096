<div class="bg-light py-5">
    <div class="container py-5">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 mx-auto">
                <div class="card">
                    <div class="card-body">
                        <img width="50%" src="https://cdn.digitalsme.cloud/profile/7ff5a892-cb1d-5066-ab74-04a8e7a1e73b/logo/Wai_Hong_Brothers_02.png">
                        <h4><b>Customer Satisfaction Survey Form  (Wai Hong Brothers Sdn Bhd)</b></h4>
                        <p>Dear Customer, Please spare your precious time and provide us your feedback on our products and services and help us to service you better.</p>
                        <hr>
                        <div class="form-group">
                            <label>Technician Name who is doing this job (Kindly ask their name) </label>
                            <input type="text" class="form-control" [(ngModel)]="data.service_by" name="service_by">
                        </div>
                        <div class="form-group">
                            <label>Name / Company Name <em class="text-danger">*</em></label>
                            <input type="text" class="form-control" [(ngModel)]="data.name" name="name">
                        </div>
                        <div class="form-group">
                            <label>Email <em class="text-danger">*</em></label>
                            <input type="email" class="form-control" [(ngModel)]="data.email" name="email">
                        </div>
                        <div class="form-group">
                            <label>Mobile phone number <em class="text-danger">*</em></label>
                            <input type="text" class="form-control" [(ngModel)]="data.contact" name="contact">
                        </div>
                        <div class="form-group">
                            <label>Document Reference No</label>
                            <input type="text" class="form-control" [(ngModel)]="data.reference_no" name="reference_no" disabled>
                        </div>

                        <div class="form-group">
                            <label>Please Select  Accordingly. Thank You ! <em class="text-danger">*</em></label>
                            <table class="table table-sm table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="text-center" *ngFor="let rate of rates">{{rate.title}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of items; let i = index">
                                        <td>{{item.title}}</td>
                                        <td class="text-center" *ngFor="let rate of item.rate; let idx = index">
                                            <input type="radio" [name]="item.identifier" (input)="ratingChange(item.identifier, rate.identifier)">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        

                        <div class="form-group">
                            <label>Additional Comment / Feedback</label>
                            <textarea rows="5" class="form-control" [(ngModel)]="data.remark" name="remark"></textarea>
                        </div>

                        <button class="btn btn-success w-100 mt-4" (click)="submit()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
