import { Component, Input, ViewChild } from '@angular/core';
import { NgChartjsDirective, NgChartjsModule } from 'ng-chartjs';
import * as Chart from 'chart.js';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'lib-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgChartjsModule]
})

export class BarChartComponent {
  lineChartData: Chart.ChartDataset[] = [];
  lineChartLabels: any = [];
  lineChartOptions: Chart.ChartOptions = {};

  @Input() set setChartOptions(val: any) {
    if(!val){
      return;
    }
    this.lineChartOptions = val;
    console.log(this.lineChartOptions);
  }

  lineChartLegend = true;

  newData: any;
  inlinePlugin: any;
  textPlugin: any;
  public lineChartType: any;

  @Input() set setData(data: { values: any, labels: any }) {
    if (!data) {
      return;
    }
    this.lineChartData = data.values;
    this.lineChartLabels = data.labels;

    console.log("chartdata1", data)
  }

  @Input() set setLengend(val:any) {
    this.lineChartLegend = val;
  }

  @Input() set setType(val: any) {
    this.lineChartType = val;
  }

          /*this.resetOptions = {
      indexAxis: this.chartOptions.indexAxis,
      responsive: this.chartOptions.responsive,
      lineTension: this.tension,
      plugins: {
        title: {
          display: this.dispTitle,
          text: this.chartTitle
        },

        legend: {
          display: this.dispLegend
        },
      },

      scales: {
        y: {
          title: {
            display: this.dispAxis,
            text: this.yTitle
          }
        },

        x: {
          title: {
            display: this.dispAxis,
            text: this.xTitle
          }
        }
      }
    };
  }
*/

}
