import { Component, Input, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ViewerService } from '../../viewer.service';

@Component({
  selector: 'lib-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  
})
export class PopupComponent implements AfterViewInit {

  @ViewChild("template", { read: ViewContainerRef }) template!: ViewContainerRef
  constructor(private viewerService: ViewerService) { }

  public params:any = {};
  public framework:any = {};
  public is_loading = false;

  @Input() framework_uuid:any;
  @Input() framework_type:any;
  @Input() paths:any;

  ngAfterViewInit(): void {
    this.init();
  }

  async init() {
    this.is_loading = true;
    this.paths.type = "popup"
    let y = await this.viewerService.getFramework(this.framework_uuid, this.framework_type, this.paths.data_uuid)  
    await this.viewerService.loadContent(y.framework.type, y.framework, y, this.template, this.paths)
    this.framework = y.framework
    this.is_loading = false;

  }
}
