import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PositiveAuthGuard } from 'lexi-auth'
import { PostComponent } from 'lexi-dashforge-top';
import { LexiChartV2Component } from './lexi-chart-v2/lexi-chart-v2.component';
import { SurveyFormComponent } from './pages/survey-form/survey-form.component';
import { DeveloperComponent } from './pages/developer/developer.component';

const routes: Routes = [
  { path: "", redirectTo: 'auth', pathMatch: 'full' },
  { path: "developer", component: DeveloperComponent },
  { path: "invitation/:uuid", loadChildren: () => import("whb-invitation").then(m => m.WhbInvitationModule) }, 
  { path: "survey/:task_uuid/:entry_uuid", component: SurveyFormComponent }, 
  { path: "chart", component: LexiChartV2Component },
  { path: "", component: PostComponent, children: [
    {path: "dashboard", loadChildren: () => import("whb-dashboard").then(m => m.WhbDashboardModule), canActivate: [PositiveAuthGuard]},
    {path: "user/profile", loadChildren: () => import("whb-dashboard").then(m => m.ProfileModule), canActivate: [PositiveAuthGuard]},  
    {path: "account", loadChildren: () => import("lexi-table-v2").then(m => m.LexiTableV2Module), canActivate: [PositiveAuthGuard]},
    {path: "task", loadChildren: () => import("lexi-table-v2").then(m => m.LexiTableV2Module), canActivate: [PositiveAuthGuard]},
    {path: "task-map", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "task-schedule", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "task-material", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "warranty", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "inventory", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "hr", loadChildren: () => import("lexi-viewer").then(m => m.LexiViewerModule), canActivate: [PositiveAuthGuard]},
    {path: "setting", loadChildren: () => import("lexi-table-v2").then(m => m.LexiTableV2Module), canActivate: [PositiveAuthGuard]},
    {path: "form", loadChildren: () => import("lexi-form").then(m => m.LexiFormModule), canActivate: [PositiveAuthGuard]},
    {path: "view", loadChildren: () => import("lexi-view").then(m => m.LexiViewModule), canActivate: [PositiveAuthGuard]},
    {path: "detail", loadChildren: () => import("lexi-table-menu").then(m => m.LexiTableMenuModule), canActivate: [PositiveAuthGuard]},  
//    {path: "test", loadChildren: () => import("lexi-chart").then(m => m.LexiChartModule), canActivate: [PositiveAuthGuard]},  
    {path: "notification", loadChildren: () => import("whb-notification").then(m => m.WhbNotificationModule), canActivate: [PositiveAuthGuard]},
    {path: "tdb", loadChildren: () => import("tdb").then(m => m.TdbModule), canActivate: [PositiveAuthGuard]},
    {path: "list", loadChildren: () => import("lexi-table-v2").then(m => m.LexiTableV2Module), canActivate: [PositiveAuthGuard]},
    {path: "document", loadChildren: () => import("whb-document").then(m => m.WhbDocumentModule), canActivate: [PositiveAuthGuard]},
    {path: "custom", loadChildren: () => import("lexi-table-menu").then(m => m.LexiTableMenuModule), canActivate: [PositiveAuthGuard]},
    {path: "import", loadChildren: () => import("whb-import").then(m => m.WhbImportModule), canActivate: [PositiveAuthGuard]},
    {path: "customer", loadChildren: () => import("whb-customer").then(m => m.WhbCustomerModule), canActivate: [PositiveAuthGuard]},
    {path: "salesman", loadChildren: () => import("whb-customer").then(m => m.WhbSalesmanModule), canActivate: [PositiveAuthGuard]},
    {path: "demo", loadChildren: () => import("lexi-form").then(m => m.LexiFormModule), canActivate: [PositiveAuthGuard]},
    {path: "hr-custom", loadChildren: () => import("whb-hr").then(m => m.WhbHrModule), canActivate: [PositiveAuthGuard]},
    {path: "temp-task", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "cost", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "others", loadChildren: () => import("whb-others").then(m => m.WhbOthersModule), canActivate: [PositiveAuthGuard]}
  ]},
  { path: "whb/project", loadChildren: () => import("whb-document").then(m => m.WhbDocumentModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
