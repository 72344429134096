import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Panzoom from '@panzoom/panzoom'

@Component({
  selector: 'lib-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  @Input() field: any = {};
  @Input() data: any = {};
  public preview:any;
  public preview_type:any;
  constructor(private modalService: NgbModal) { 

  }

  ngOnInit(): void {
  }

  viewImg(content:any, img:any) {
    const fileType = img.substr(img.length - 3);
    if(fileType == 'pdf'){
      window.open((img).replace(/#/, "%23"), "_blank");
      return;
    }
    
		this.modalService.open(content, {})
    setTimeout(() => {
      this.initZoom();
    }, 1000)
    this.preview = img;
    this.preview_type = fileType;
	}

  initZoom() {
    const elem = document.getElementById('popupImage')
    if(!elem){
      return;
    }
    const panzoom = Panzoom(elem, {
      maxScale: 5
    })
    elem.parentElement?.addEventListener('wheel', panzoom.zoomWithWheel)
  }


  checkType(data:any) {
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
  }
  
}
