import { Injectable, Inject } from "@angular/core";
import { LexiDashforgeTopOptions } from './lexi-dashforge-top.options';
import { ApiService } from 'lexi-api';

@Injectable({
  providedIn: 'root'
})
export class LexiDashforgeTopService {

  private config: any;
  private _post_routes: any = [];
  constructor(
    private api: ApiService,
    @Inject('config') config: any
  ) { 
    const options : LexiDashforgeTopOptions = {
      "app_title": "lexi<span>global</span>",
      "app_desc": "Dashforge Top Template",
      "company_name": "Lexi Global Sdn Bhd",
      "web_url": "https://lexiglobal.my",
      "is_dsme": false
    };
    this.config = {...options, ...config};
  }

  public get() {
    return this.config;
  }

  public setPostRoute(routes: any) {

    if(!routes || routes.length == 0) {
      return;
    }

    this._post_routes = this._post_routes.concat(routes);
  }

  public getPostRoute() {
    return this._post_routes;
  }
  
}