

<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>

    <lib-dropzone [setPredefinedSupportedFileTypes]="'media'" (completedFiles)="getFiles($event, field)" [maxUpload]="maxLoad" [path]="'whb/media/'"></lib-dropzone>

    <!-- <div class="input-group mb-2">
        <input [formControl]="ctrl" type="text" class="form-control form-control-sm" [id]="field.mapping.key" readonly>
        <button class="btn btn-outline-dark btn-sm" type="button" (click)="addMedia()">Add Media</button>
    </div> -->

    <div [ngSwitch]="mediaType">
        <div *ngSwitchCase="'single'">
            <img class="w-100 mb-4" [src]="images">
        </div>
        <div *ngSwitchCase="'multiple'">
            <div *ngFor="let img of images; let i = index;" class="d-flex justify-content-between">
                <button class="btn btn-link p-0 text-left" type="button" (click)="viewImg(content, img)">{{img}}</button>
                <span> 
                    <button class="btn btn-link px-2 py-0" type="button" (click)="removeImg(i)"><svg fill="red" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg></button>
                    <button class="btn btn-link px-2 py-0" type="button" (click)="downloadImg(img)"><svg fill="black" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23 24v-20h-8v2h6v16h-18v-16h6v-2h-8v20h22zm-12-13h-4l5 6 5-6h-4v-11h-2v11z"/></svg></button>
                </span>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
	<div class="modal-body">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
		<img class="w-100 mb-4" [src]="preview" id="popupImage">
	</div>
</ng-template>



