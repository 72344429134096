import { Component, AfterViewInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements AfterViewInit {

  @Input() field: any = {};
  @Input() set conditionalVal(val:any) {
    console.log(val)

  };
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    this.data = this.ctrl.value && this.ctrl.value.length == 0 ? [{title: "", descp:""}] : this.ctrl.value;
  }

  public conditionValue:boolean = false;
  public ctrl:any;
  public data:any = [{title: "", descp:""}];

  constructor() { }

  ngAfterViewInit(): void {
  }

  add(){
    this.data.push({title: "", descp:""})
  }

  remove(ind:any){
    if(this.data.length == 1 || ind == 0){
      return;
    }

    this.data.splice(ind, 1)
  }

  emitData(){
    this.ctrl.setValue(this.data)
  }

}
