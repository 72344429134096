import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { FormsModule } from '@angular/forms';
import { FormRoutingModule } from './form.routing.module';
import { StatusComponent } from '../component/status/status.component';
import { SelectComponent } from '../component/select/select.component';
import { TextComponent } from '../component/text/text.component';
import { HiddenComponent } from '../component/hidden/hidden.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextareaComponent } from '../component/textarea/textarea.component';
import { AutocompleteComponent } from '../component/autocomplete/autocomplete.component';
import { ReadonlyComponent } from '../component/readonly/readonly.component';
import { CheckboxComponent } from '../component/checkbox/checkbox.component';
import { RadioComponent } from '../component/radio/radio.component';
import { FileUploaderComponent } from '../component/file-uploader/file-uploader.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LexiResourceModule } from 'lexi-resource';
import { DropzoneComponent } from 'lexi-resource';

import { LexiWysiwygModule } from 'lexi-wysiwyg';
import { WysiwygComponent } from '../component/wysiwyg/wysiwyg.component';
import { MapComponent } from '../component/map/map.component';
import { MultiCheckboxComponent } from '../component/multi-checkbox/multi-checkbox.component';
import { MultiSelectComponent } from '../component/multi-select/multi-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileComponent } from '../component/mobile/mobile.component';
import { YesnoComponent } from '../component/yesno/yesno.component';
import { LabelComponent } from '../component/label/label.component';
import { MediaComponent } from '../component/media/media.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultiSelectCheckboxComponent } from '../component/multi-select-checkbox/multi-select-checkbox.component';
import { VerticalWarrantyComponent } from '../component/vertical-warranty/vertical-warranty.component';
import { ImportExportComponent } from '../component/import-export/import-export.component';
import { TextOnlyComponent } from '../component/text-only/text-only.component';
import { AccordionComponent } from '../component/accordion/accordion.component';
import { TableComponent } from '../component/table/table.component';
import { MultiTextComponent } from '../component/multi-text/multi-text.component';

@NgModule({
  declarations: [
    FormComponent,
    TextComponent,
    StatusComponent,
    SelectComponent,
    HiddenComponent,
    TextareaComponent,
    ReadonlyComponent,
    AutocompleteComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploaderComponent,
    WysiwygComponent,
    ImportExportComponent,
    MapComponent,
    MultiCheckboxComponent,
    MultiSelectComponent,
    MobileComponent,
    YesnoComponent,
    LabelComponent,
    MediaComponent,
    MultiSelectCheckboxComponent,
    VerticalWarrantyComponent,
    TextOnlyComponent,
    AccordionComponent,
    TableComponent,
    MultiTextComponent
  ],
  imports: [
    CommonModule,
    FormRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    LexiResourceModule,
    LexiWysiwygModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    DropzoneComponent
  ]
})

export class FormModule { }
