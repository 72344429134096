<canvas id="testChart" #ngChartjs="ngChartjs"
 ngChartjs
  [datasets]="lineChartData" 
  [labels]="lineChartLabels"
  [options]="lineChartOptions"
  [legend]="lineChartLegend"
  [chartType]="lineChartType"
  [inlinePlugins]="inlinePlugin"></canvas>

  <!-- <button (click)="check()">check</button> -->
