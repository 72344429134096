import { Component } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent {

  constructor(private api: ApiService) { }

}
 