import { Component, AfterViewChecked, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.css']
})
export class FilterDateComponent implements AfterViewChecked {

  public search:any = {};

  @ViewChild("filterView", {static: true}) filterView!: ElementRef;
  @Input() set setFilter (val:any){
    if (val && (val.from || val.to)) {
      (<HTMLInputElement>document.getElementById("dateFrom")).value = val.from;
      (<HTMLInputElement>document.getElementById("dateTo")).value = val.to;
    }
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor(public translate: LexiTranslationHelper) { }

  ngAfterViewChecked(): void {
  }

  detectInput() {
    this.search.from = (<HTMLInputElement>document.getElementById("dateFrom")).value
    this.search.to = (<HTMLInputElement>document.getElementById("dateTo")).value

    this.outputEvent.emit(this.search)
  }

}
