import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LexiTableV2Service {

  private func: any = {};

  constructor() { }

  setFunction(name: string, func:any) {
      this.func[name] = func;
  }

  getFunction(name: string, val:any) {
      return () => {return this.func[name](val)};
  }
  
  listFunction() {
    return this.func;
  }

}
