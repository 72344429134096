import { NgModule } from '@angular/core';
import { LexiTableMenuRoutingModule } from './lexi-table-menu.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LexiTableMenuRoutingModule
  ],
  exports: [
  ]
})
export class LexiTableMenuModule { }
