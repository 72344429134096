import { ElementRef, ViewChild, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiTableService } from '../lexi-table.service';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements AfterViewInit, OnInit {

  @Input() set setTableOption(table_option:TableOption) {
    if(table_option) {
      this.table_option = {...this.table_option, ...table_option};
    }
  }
  
  @Input() set setTableIdentifier(table_identifier:string) {
    if(table_identifier) {
      this.table_identifier = table_identifier;
      this.table_identifier_guard = table_identifier;
    }
  }

  @Input() set setFilter(filter:any) {
    if(filter) {
      this.filter = filter;
    }
  }

  @Input() set setSearch(filter:any) {
    this.searchValue = filter;
    // this.getTable()
  }

  @Input() set setHideHeader(status:any) {
    if(status) {
      this.hide_header = true;
    }
  }

  private table_identifier_guard: string | null = null

  table_identifier: string = "";
  public table_uuid:any;
  public hide_header:any = false;
  public structure:any = {};
  public filter:any = {};
  public loading: boolean = true;
  public table_not_found: boolean= false;
  public subview: boolean = false;
  private subview_identifier:any;
  private subview_uuid:any;
  public searchValue:any = {};
  public table_option: TableOption = {method : "normal", wrapperClass: "col-12 col-md-12"}
  public defaultSort:any = {key: "id", type: "DESC"};

  @ViewChild("buttonsView", {read: ViewContainerRef}) buttons!: ViewContainerRef;
  @ViewChild("table", {static: true}) table!: ElementRef;
  @ViewChild("filtersView", {read: ViewContainerRef}) filters!: ViewContainerRef;
  @ViewChild("filtersDateView", {read: ViewContainerRef}) filtersDate!: ViewContainerRef;
  @ViewChild("navigationView", {read: ViewContainerRef}) pageNavigation!: ViewContainerRef;

  @Output() eventTableData:any = new EventEmitter();
  
  constructor(private route: ActivatedRoute, private apiService: ApiService, private renderer: Renderer2, private router: Router, private tableSerivce: LexiTableService, private translate: LexiTranslationHelper) {

    let snapshot:any = route.snapshot.data;

    if(snapshot.subview) {
      this.subview = snapshot.subview;
      this.route.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    this.route.params.subscribe(async (val: any) => {
      if(!val.table_identifier || this.table_identifier_guard) {
        return;
      }

      this.table_identifier = val.table_identifier;
      this.table_uuid = val.uuid;
      await this.getTable();
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getTable();
  }

  resetTable() {
    this.table_not_found = false;
    this.loading = true;
    this.table.nativeElement.innerHTML = "";
    this.structure = {}
    if(!this.hide_header) {
      this.buttons.clear();
      this.filters.clear();
      this.filtersDate.clear();
    }
  }

  async getTable() {    
    this.table.nativeElement.innerHTML = "";

    if(!this.table) {
      return;
    }

    this.resetTable();
 
    var data:any = {}
        data['identifier'] = this.table_identifier;
        data['uuid'] = this.table_uuid;
        data['filter'] = {}
        if(this.subview_identifier && this.subview_uuid){
          data['filter']['identifier'] = this.subview_identifier;
          data['filter']['uuid'] = this.subview_uuid;
        }
        data['filter']['sortBy'] = this.defaultSort;

        if(this.filter) {
          data['filter'] = {...data['filter'], ...this.filter}
        }

        data['filter']['table_filter'] = this.searchValue;

    let structure = await this.apiService.post("/table/structure", data);
    
    this.eventTableData.emit(structure);

    if(!structure.status) {
      this.table_not_found = true;
      this.loading = false;
      return;
    }

    this.structure = structure.structure

    if(!this.hide_header) {
      await this.createButtons();
      await this.createFilters();
      if(this.structure.filter_date && this.structure.filter_date == 1){
        await this.createFiltersDate();
      }
    }

    if(this.structure.page_nat && this.structure.page_nat == 1){
      await this.createPagination();
    }

    await this.generateTable();


    this.loading = false;
  }

  async createButtons() {

    if(!this.structure.buttons || this.structure.buttons == 0){
      return;
    }

    this.buttons.clear();

    for(var x = 0; x < this.structure.buttons.length; x++) {

      let data = this.structure.buttons[x];
      data.subview = this.subview;

      switch(data.type) {
        case "export":
          if(data.action){
            data.action = JSON.parse(data.action)
          }
          const exportBtn = ((await import("./export/export.component")).ExportComponent)
          const exportComponentInstance = this.buttons.createComponent(exportBtn);
          this.renderer.addClass(exportComponentInstance.location.nativeElement, "ms-1")
    
          exportComponentInstance.instance.identifier = this.table_identifier; 
          exportComponentInstance.instance.button = data; 
          exportComponentInstance.instance.data = this.structure.data;

        break;
        default :
          const buttonComponent = ((await import("./button/button.component")).ButtonComponent)
          const buttonComponentInstance = this.buttons.createComponent(buttonComponent);
          this.renderer.addClass(buttonComponentInstance.location.nativeElement, "ms-1")
    
          buttonComponentInstance.instance.button = data; 
          buttonComponentInstance.instance.functionEmitter.subscribe((action:any) => {
            let func = this.tableSerivce.getFunction(action, this.structure.data)
            func();
          })
        break;
      }
    }
  }

  async createFilters() {
    if(!this.structure.filter || this.structure.filter.length == 0){
      return;
    }

    this.filters.clear();

    const filterComponent = ((await import("./filter/filter.component")).FilterComponent)
    const filterComponentInstance = this.filters.createComponent(filterComponent);
    this.renderer.addClass(filterComponentInstance.location.nativeElement, "me-3")

    filterComponentInstance.instance.setFilter = this.searchValue['search'];

    filterComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
      console.log(val)

      this.searchValue['search'] = val;
      this.getTable()
    })

  }

  async createFiltersDate() {
    this.filtersDate.clear();

    const filterComponent = ((await import("./filter-date/filter-date.component")).FilterDateComponent)
    const filterComponentInstance = this.filtersDate.createComponent(filterComponent);
    this.renderer.addClass(filterComponentInstance.location.nativeElement, "me-3")
    filterComponentInstance.instance.setFilter = this.searchValue['in_between'];

    filterComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
      this.searchValue['in_between'] = val;
      this.getTable()
    })
  }

  async createPagination(){
    this.pageNavigation.clear();

    const natComponent = ((await import("./page-navigation/page-navigation.component")).PageNavigationComponent)
    const natComponentInstance = this.pageNavigation.createComponent(natComponent);
    natComponentInstance.instance.pagination = this.structure.pagination

    natComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
      this.searchValue['pages'] = val;
      this.getTable()
    })
  }

  async generateTable() {
    if(!this.structure.fields || this.structure.fields.length == 0) {
      return;
    }

    let table = document.createElement("table");

    this.renderer.addClass(table, "table");
    this.renderer.addClass(table, "table-striped");
    this.renderer.addClass(table, "table-sm");

    let head = document.createElement("tr");
    
    for(var x = 0; x < this.structure.fields.length; x++) {

      let current = this.structure.fields[x]

      if(!current.table_view) {
        continue;
      }

      let field = document.createElement("th");
      this.renderer.addClass(field, "bg-primary");
      this.renderer.addClass(field, "text-light");

      if(current['type'] == "checkbox"){

        const masterCheckbox = document.createElement('input')
        masterCheckbox.type = "checkbox";
        if(current['mapping']['value']){
          masterCheckbox.checked = current['mapping']['value'];
        }
        masterCheckbox.onclick = () => {
          current['mapping']['value'] = !current['mapping']['value']
          this.checkList(this.structure.data, current['mapping']['key'], current['mapping']['value'])
        }
        field.append(masterCheckbox)

      }else{
    //    field.innerHTML = current['title']  
        field.innerHTML = this.translate.instant(current['title'])
      }
     
      if(current.allow_sort){
        field.innerHTML += '<span class="ms-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="rgb(255,255,255)" d="M12 0l8 10h-16l8-10zm8 14h-16l8 10 8-10z"/></svg></span>'
        field.onclick = () => {this.sorting(current['mapping']['key'])}
      }
      head.append(field)
    }

    let thead = document.createElement("thead");
    thead.append(head)
    table.append(thead);

    let tbody = document.createElement("tbody")

    for(var y = 0; y < this.structure.data.length; y++) {
      let row = document.createElement("tr");
      let data = this.structure.data[y]
      for(var x = 0; x < this.structure.fields.length; x++) {

        let current = this.structure.fields[x]

        if(!current.table_view) {
          continue;
        }

        let cell = document.createElement("td");

        if(current['support'] && current['support']['style'] && current['support']['style'].length > 0) {
          let styles = current['support']['style'];

          for(var z = 0; z < styles.length; z++) {
            this.renderer.addClass(cell, styles[z]);
          }
        }

        var value: string = "";
        switch(current['type']) {

          case "index":
            const itemIndex = y + 1;
            // value = ((this.structure.currentPage - 1) * this.structure.perPage + itemIndex).toString();
            // cell.innerHTML = value;
            value = ((this.structure.pagination.current_page - 1) * this.structure.pagination.perPage + itemIndex).toString();
            cell.innerHTML = value;
          break;

          case "status":
            let status = this.structure.data[y][current['mapping']['key']];
            value = !status || status == 0 ? 'Disabled' : "Active";
            value = this.translate.instant(value)
            cell.innerHTML = value;
          break;

          case "boolean":
            let boolean = this.structure.data[y][current['mapping']['key']];
            value = !boolean || boolean == 0 ? 'No' : "Yes";
            value = this.translate.instant(value)
            cell.innerHTML = value;
          break;

          case "price":
            let price = this.structure.data[y][current['mapping']['key']];
            value = "<span>RM</span><span>" + price.toFixed(2) + "</span>"

            this.renderer.addClass(cell, "d-flex")
            this.renderer.addClass(cell, "flex-row")
            this.renderer.addClass(cell, "justify-content-center")
            cell.innerHTML = value;
          break;

          case "date":
            if(data[current['mapping']['key']]) {
              const date = new Date(data[current['mapping']['key']]);
              value = ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-"  + date.getFullYear();
            }
            cell.innerHTML = value;
          break;

          case "checkbox":

            const input = document.createElement("input");
            input.type = "checkbox";
            input.name = current['mapping']['key']+y;
            input.setAttribute('ng-model', current['mapping']['key'])
            if(data[current['mapping']['key']]){
              input.setAttribute('checked', data[current['mapping']['key']])
            }

            input.onclick = () => {this.checkList([data], current['mapping']['key'])}
            cell.append(input)
          break;

          case "function":
            const func = document.createElement("a")
            func.innerHTML = this.translate.instant(current['title'])
            func.onclick = this.tableSerivce.getFunction(current['mapping']['key'], data);
            func.href = "javascript:void(0)"
            cell.append(func)
          break;

          case "link":
            const view = document.createElement("a")
            view.innerHTML = this.translate.instant(current['title'])
            view.onclick = () => {this.link(current['mapping']['key'] + "/" + this.table_identifier + "/" + data[current['mapping']['variable']], data)}
            view.href = "javascript:void(0)"
            cell.append(view)
          break;

          case "link-no-table-mapping":
            if(current['mapping']['to_groupLink']){
              data['skip_subview'] = true;
            }

            const viewnomapping = document.createElement("a")
            viewnomapping.innerHTML = this.translate.instant(current['title'])
            viewnomapping.onclick = () => {this.link(current['mapping']['key'] + "/" + data[current['mapping']['variable']], data)}
            viewnomapping.href = "javascript:void(0)"
            cell.append(viewnomapping)
          break;

          case "group-link":
            const viewEI = document.createElement("a")
            viewEI.innerHTML = this.translate.instant(current['title'])
            viewEI.onclick = () => {this.link(current['mapping']['key'] + "/" + data[current['mapping']['variable']], data, current['mapping']['target'])}
            viewEI.href = "javascript:void(0)"
            cell.append(viewEI)
          break;

          case "delete":
            const remove = document.createElement("a")
            remove.innerHTML = this.translate.instant(current['title'])
            remove.onclick = () => {this.remove(current['mapping']['key'], data[current['mapping']['variable']])}
            remove.href = "javascript:void(0)"
            cell.append(remove)
          break;

          case "whb-mobile":
            value = this.structure.data[y][current['mapping']['key']]
            // let index = value.length - 4;
            // // cell.innerHTML = value.substring(0, index) + " " + value.substring(index);;
            cell.innerHTML = this.standardizePhoneNumber(value);
          break;

          default:
          case "string":
            value = this.structure.data[y][current['mapping']['key']]
            cell.innerHTML = value;
          break;
        }

        row.append(cell)
      }
      tbody.append(row)
    }

    table.append(tbody)

    this.table.nativeElement.append(table);

  }

  link(path:string, data:any = {}, target = "_self") {
    console.log(data)
    if(this.subview && !data['skip_subview']) {
      let actions = path.split("/");

      let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[0], actions[1], actions[2]]

      if(target == "_blank") {
        window.open(routes.join("/"), "_blank")
        return
      }

      this.router.navigate(routes, {state: {data, fields: this.structure.fields}})
      return;
    }

    if(target == "_blank") {
      window.open(path, "_blank")
      return
    }
    
    this.router.navigate([path], {state: {data, fields: this.structure.fields}})
  }

  async remove(path: string, identifier: string) {
    await this.apiService.post(path, {table_identifier: this.table_identifier, uuid: identifier});
    await this.getTable();
  }

  standardizePhoneNumber(phoneNumber: any) { 
    var phoneNumber = phoneNumber.replace(/\D/g, ""); 
    if ((phoneNumber.length == 11 || phoneNumber.length == 10) && phoneNumber[0] == '0') { 
      phoneNumber = "+6" + phoneNumber; 
    } else if ((phoneNumber.length == 12 || phoneNumber.length == 11) && phoneNumber[0] == '6') { 
      phoneNumber = "+" + phoneNumber; 
    } else{
      phoneNumber = "+60" + phoneNumber; 
    }
    return phoneNumber.slice(0, 5) + '-' + phoneNumber.slice(5, phoneNumber.length - 4) + " " + phoneNumber.slice(phoneNumber.length - 4); 
  }

  async sorting(key:any) {
    this.defaultSort['key'] = key;
    this.defaultSort['type'] = this.defaultSort['type'] == 'ASC' ? "DESC" : "ASC";
    this.getTable()
  }

  checkList(data:any, key:any, masterValue?:boolean) {
    if(data.length > 1){
      for(let x = 0; x < data.length; x++){
        data[x][key] = masterValue;
      }
    }else{
      for(let x = 0; x < data.length; x++){
        data[x][key] = !data[x][key]
      }
    }
    
    this.table.nativeElement.innerHTML = "";
    this.generateTable()
  }

}

export interface TableOption {
  method?:string, // normal, popup, embed
  wrapperClass?: string
}