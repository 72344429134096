<div class="form-group">
    <label [for]="fields.mapping.key">{{fields.title}} <em *ngIf="fields.required">*</em>
        <ng-container *ngIf="fields.descp">
            <br>
            <small class="text-muted">{{fields.descp}}</small>
        </ng-container>
    </label>

    <div class="input-group">
        <select class="form-control form-control-sm" [id]="fields.mapping.key" [formControl]="ctrl" (change)="detectChanges($event)" [disabled]="fields.editable && fields.editable == 0" >
            <ng-container *ngIf="options && options.length > 0">
                <option *ngFor="let option of options" [value]="option.val">{{option.title}}</option>
            </ng-container>
            <ng-container *ngIf="!options || options.length == 0">
                <option disabled>No data found.</option>
            </ng-container>
        </select>
        <div class="input-group-append">
          <button class="btn btn-sm btn-outline-dark" type="button" (click)="clearSelect()">Clear</button>
        </div>
    </div>
    
</div>