
<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <div class="input-group">
        <input class="file-upload form-control" type="file" (change)="saveFile($event)">
        <div class="input-group-append">
            <button type="button" class="btn btn-primary input-group-text"  (click)="downloadFile(ctrl.value)">Export</button>
        </div>
    </div>
</div>

