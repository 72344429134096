import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.css']
})
export class ButtonDropdownComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translate: LexiTranslationHelper) { }

  ngOnInit(): void {
  }

  
}
