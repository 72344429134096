import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent {

  public ctrl:any;
  public matchingData:any = null;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }

  @Input() set setRelated(val:any){
    if(this.field.mapping && this.field.mapping.is_show && val && this.field.mapping.is_show == val ){
      this.matchingData = val
    }else{
      this.matchingData = null
    }
  };
  
  constructor() { }

}
