import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from "lexi-api";
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

  public task_uuid:any;
  public entry_uuid:any;
  public data:any = {items:{}};
  public isLoading:boolean = false;
  public items:any = []
  public rates:any = []

  constructor(private activateRoute: ActivatedRoute, private router: Router, private api: ApiService, private notify: LexiNotifyService) { 
    if(!this.activateRoute.snapshot.paramMap.get("task_uuid") || !this.activateRoute.snapshot.paramMap.get("entry_uuid")){
      window.location.href = 'https://waihongbrothers.com/';
      return;
    }

    this.task_uuid = this.activateRoute.snapshot.paramMap.get("task_uuid");
    this.entry_uuid = this.activateRoute.snapshot.paramMap.get("entry_uuid");
    this.initData();
  }

  ngOnInit(): void {
  }

  async initData() {
    this.items = await this.api.post("/task/get/survey-items", {})
    this.rates = this.items[0]['rate'];

    const result = await this.api.post("/task/get/survey-detail", {task_uuid: this.task_uuid, entry_uuid: this.entry_uuid})
    if(!result.status){
      window.location.href = 'https://waihongbrothers.com/';
      return;
    }
    this.data = result.data;
    this.data.items = {};
  }

  async ratingChange(item_identifier:any, rate_identifier:any) {
    this.data.items[item_identifier] = rate_identifier;
  }

  async submit(){
    this.isLoading = true;
    const result = await this.api.post("/task/save/survey-detail", this.data)
    this.isLoading = false;

    if(!result.status){
      return this.notify.error("Submit failed! Please try again.")
    }

    this.notify.success("Thank for your feedback.")
    window.location.href = 'https://waihongbrothers.com/';
  }
}
