import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartjsModule } from 'ng-chartjs';
import * as Chart from 'chart.js';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'lib-scatter',
  templateUrl: './scatter.component.html',
  styleUrls: ['./scatter.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgChartjsModule]

})

export class ScatterComponent {
  scatterData: Chart.ChartDataset[] = [];
  updateData: any;
  ngChartjs: any;
  scatterOptions:any;

  @Input() set setScatterData(val: any) {
    if (!val) {
      return;
    }
    this.scatterData = val;
  }

  @Input() set setScatterOption(val:any){
    this.scatterOptions=val;
  }

  inlinePlugin: any;

  scatterLegend = true;
  @Input() set setScatterLegend(val:any){
    this.scatterLegend = val;
  }

  chartColor = [{
    backgroundColor: '#ff0000'
  }];

  constructor() { }
}
