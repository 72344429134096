import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LexiTranslationService {

  private langFiles = [];

  public get() {
    return this.langFiles;
  }

  public set(value:[]) {
    if(!value) {
      return;
    }

    if(Array.isArray(value)) {
      this.langFiles = this.langFiles.concat(value)
    } else {
      this.langFiles.push(value);
    }
  }

}
