import { Component, AfterViewInit, ViewContainerRef, ViewChild, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiTranslationHelper } from 'lexi-translation';
import { LexiViewService } from '../lexi-view.service';

@Component({
  selector: 'lib-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements AfterViewInit {

  private view_identifier: any;
  private data_uuid: any;

  public loading: boolean = true;
  public structure : any = {};
  public subview:boolean = false;
  public subview_identifier:any;
  public subview_uuid:any;
  public redirectLink:any;

  @ViewChild("wrapper", {read: ViewContainerRef}) wrapper!: ViewContainerRef;
  @ViewChild("buttonsView", {read: ViewContainerRef}) buttons!: ViewContainerRef;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router, public translate: LexiTranslationHelper, private renderer: Renderer2, private viewService: LexiViewService) { 

    let snapshot:any = activatedRoute.snapshot.data;
    if(snapshot.subview) {
      this.subview = snapshot.subview;
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    this.activatedRoute.params.subscribe(async (v: any) => {
      if(v.view_identifier && (!this.view_identifier || v.view_identifier !== this.view_identifier)){
        this.view_identifier = v.view_identifier;
        this.data_uuid = v.uuid;
        this.loadForm()
      }
    })    
  }

  ngAfterViewInit(): void {
  }

  async loadForm() {
    this.loading = true;
    this.structure = {}

    let structure = await this.api.post("/view/structure", {identifier: this.view_identifier, data_uuid: this.data_uuid})
    if(!structure.status && structure.redirect) {
      this.router.navigate([structure.redirect])
      return;
    }
 
    if(!structure.status) {
      return;
    }

    this.structure = structure.structure;
    if(!this.subview){
      this.redirectLink = "/"+this.structure.redirect_url.key +"/"+ this.structure.identifier +"/"+ this.structure.data[this.structure.redirect_url.variable]
    }else{
      this.redirectLink = "/detail/"+this.subview_identifier +"/"+ this.subview_uuid +"/"+ this.structure.redirect_url.key +"/"+ this.structure.identifier +"/"+ this.structure.data[this.structure.redirect_url.variable]
    }

    await this.createButtons();

    if(this.structure.fields.length > 0) {
      this.wrapper.clear();
      await this.build();
    }
    this.loading = false;
  }

  async createButtons() {

    if(!this.structure.buttons || this.structure.buttons == 0){
      return;
    }

    this.buttons.clear();

    for(var x = 0; x < this.structure.buttons.length; x++) {
  
      let data = this.structure.buttons[x];
      data.subview = this.subview;

      switch(data.type) {
        case "export":
          if(data.action){
            data.action = JSON.parse(data.action)
          }
          const exportBtn = ((await import("./export/export.component")).ExportComponent)
          const exportComponentInstance = this.buttons.createComponent(exportBtn);
          this.renderer.addClass(exportComponentInstance.location.nativeElement, "ms-1")
    
          exportComponentInstance.instance.identifier = this.view_identifier; 
          exportComponentInstance.instance.button = data; 
          exportComponentInstance.instance.data = this.structure.data;

        break;
        default :
          const buttonComponent = ((await import("./button/button.component")).ButtonComponent)
          const buttonComponentInstance = this.buttons.createComponent(buttonComponent);
          this.renderer.addClass(buttonComponentInstance.location.nativeElement, "ms-1")
    
          buttonComponentInstance.instance.identifier = this.view_identifier; 
          buttonComponentInstance.instance.view_uuid = this.structure.data['uuid']
          buttonComponentInstance.instance.data = this.structure.data;
          buttonComponentInstance.instance.button = data; 
          buttonComponentInstance.instance.functionEmitter.subscribe((action:any) => {
            let func = this.viewService.getFunction(action, this.structure.data)
            func();
          })
        break;
      }
    }

  }

  async build() {
    this.wrapper.clear();

    let component;
    let componentInstance;
    for(var x = 0; x < this.structure.fields.length; x++) {

      let field = this.structure.fields[x];
      field.title = this.translate.instant(field.title)
      switch(field.type) {
        case "text": 
          component = ((await import("../component/text/text.component")).TextComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
        break;
        case "date": 
          component = ((await import("../component/date/date.component")).DateComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
        break;
        case "yesno": 
          component = ((await import("../component/yesno/yesno.component")).YesnoComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case "status": 
          component = ((await import("../component/status/status.component")).StatusComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case "media": 
          component = ((await import("../component/media/media.component")).MediaComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case "vertical-warranty":
          component = ((await import("../component/vertical-warranty/vertical-warranty.component")).VerticalWarrantyComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case "link":
          component = ((await import("../component/link/link.component")).LinkComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case "json":
          component = ((await import("../component/json/json.component")).JsonComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);
          break;
        case 'multi-text':
          component = ((await import("../component/multi-text/multi-text.component")).MultiTextComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field;
          componentInstance.instance.data = this.structure.data;
          this.setSize(componentInstance, field);

          break;
        default:
          console.warn("Undefined Filed Type", field.type)
        break;
      }

    };

  
  //    this.setSize(wysiwygComponentInstance, field);
  
  }

  setSize(element:any, field:any) {
    if(field.mobile_size) {
      this.renderer.addClass(element.location.nativeElement, field.mobile_size)
    }

    if(field.size) {
      this.renderer.addClass(element.location.nativeElement, field.size)
    }

    if(field.line_break) {
     let d = document.createElement("div");
     d.classList.add("col-12") 
     element.location.nativeElement.parentElement.insertBefore(d, element.location.nativeElement.nextSibling)
    }

    if(field.descp_on_top) {
      let d = document.createElement("p");
//      d.classList.add("mb-0")
      d.classList.add("small") 
      d.innerHTML = field.descp_on_top;

      element.location.nativeElement.firstChild.insertBefore(d, element.location.nativeElement.firstChild.firstChild.nextSibling);
      this.renderer.addClass(element.location.nativeElement.firstChild, "label-mb-0")
    }

     if(field.descp_on_bottom) {
      let d = document.createElement("p");
      d.classList.add("mb-0")
      d.classList.add("small") 
      d.innerHTML = field.descp_on_bottom;

      element.location.nativeElement.firstChild.append(d)
     }
  }
}
