import { Component, AfterViewInit, Input, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceManagerComponent } from 'lexi-resource';
import Panzoom from '@panzoom/panzoom'

@Component({
  selector: 'lib-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements AfterViewInit {

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl;
    this.init();
  }

  public ctrl:any;
  public mediaType:any;
  public images:any;
  public maxLoad:any = 1;
  public preview:any;
  constructor(private modalService: NgbModal) { }

  ngAfterViewInit(): void {
    this.init();
  }

  async init() {
    // let dropzone:any = ((await import("lexi-resource")).DropzoneComponent);
    // let com:any = this.template.createComponent(dropzone);

    // com.instance.setPredefinedSupportedFileTypes = 'media';
    // com.instance.maxUpload = 'MaxLoad';
    // com.instance.path = 'whb/media/';
    // com.instance.completedFiles.subscribe((v:any) => {
    //   this.getFiles(v, this.field)
    // })

    this.mediaType = this.field['mapping']['media_type'] ?? "single";

    if(this.mediaType === 'multiple'){
      this.maxLoad = 999;
      this.images = [];
      if(this.ctrl.value){
        this.images = this.ctrl.value
        if (typeof this.images === 'string') {
          this.images = JSON.parse(this.images)
        }
      }
      // this.ctrl.setValue(JSON.stringify(this.images))
      this.ctrl.setValue(this.images)
      
    }else{
      this.maxLoad = 1;
      this.images = '';
      if(this.ctrl.value){
        this.images = this.ctrl.value;
      }
    }
  }

  initZoom() {
    const elem = document.getElementById('popupImage')
    if(!elem){
      return;
    }
    const panzoom = Panzoom(elem, {
      maxScale: 5
    })
    elem.parentElement?.addEventListener('wheel', panzoom.zoomWithWheel)
  }

  addMedia() {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setPath = "whb/media/"
    modal.componentInstance.selectType = this.mediaType;
    modal.componentInstance.setPredefinedSupportedFileTypes = "media";
    modal.componentInstance.getSelected.subscribe((v:any) => {
      if(this.mediaType === 'single'){
        this.ctrl.setValue(v[0]['key'])
        this.images = this.ctrl.value;
      }else{
        v.forEach((img:any) => {
          this.images.push(img['key'])
        });
        this.ctrl.setValue(JSON.stringify(this.images))
      }
      modal.close();
    })
  }

  removeImg(index:number){
    this.images.splice(index, 1)
  }

  viewImg(content:any, img:any) {
    const fileType = img.substr(img.length - 3);
    if(fileType == 'pdf'){
      window.open((img).replace(/#/, "%23"), "_blank");
      return;
    }
    
		this.modalService.open(content, {})
    setTimeout(() => {
      this.initZoom();
    }, 1000)
    this.preview = img;
	}

  getFiles(files:any, field:any) {    
    if(this.mediaType === 'single'){
      this.ctrl.setValue(files[0]['path'])
      this.images = this.ctrl.value;

    }else{
      // this.images = JSON.parse(this.images)
      files.forEach((img:any) => {
        this.images.push(img['path'])
      });
      // this.ctrl.setValue(JSON.stringify(this.images))
      this.ctrl.setValue(this.images)
    }
  }

  async downloadImg(url:any){
    const filename = url.substring(url.lastIndexOf('/') + 1);
    
    const image = await fetch(url)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.setAttribute('href', imageURL);
    link.setAttribute('download', filename);
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
