
<div class="card w-100 h-100">
    <div class="card-body"> 
        <ng-container *ngIf="!is_loading">
            <h3 *ngIf="framework.structure.show_title">{{framework.title}}</h3>
            <h5 *ngIf="framework.structure.show_descp">{{framework.descp}}</h5>
        </ng-container>
        <div class="spinner-border" role="status" *ngIf="is_loading">
            <span class="sr-only">Loading...</span>
        </div>
        <ng-template #content></ng-template>
    </div>
</div>