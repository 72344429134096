import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent {

  public ctrl:any;
  public fields:any;
  public options:any = [];

  // @Input() field: any = {};
  @Input() set setRelated(val:any){
    this.options = [];
    
    this.options = this.fields.options.filter((item:any) => {
        return item['related'] == val;
      });
  };
  @Input() set field(val:any) {
    this.fields = val
    this.options = this.fields.options;
  }
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    if(!this.ctrl.value) {
      this.ctrl.value = []
    }
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() {}

  detectChanges($e:any) {
    let d:any = {key: this.fields.mapping.key, togglers: this.fields.mapping.related_toggler}
    this.outputEvent.emit(d)
  }
}
