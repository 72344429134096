
<div class="form-group">
    <label [for]="fields.mapping.key">{{fields.title}} <em *ngIf="fields.required">*</em>
        <ng-container *ngIf="fields.descp">
            <br>
            <small class="text-muted">{{fields.descp}}</small>
        </ng-container>
    </label>
    <ng-multiselect-dropdown
        [placeholder]="''"
        [settings]="dropdownSettings"
        [data]="options"
        [(ngModel)]="ctrl.value"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
        (onDeSelect)="onDeSelect($event)"
        (onDeSelectAll)="onDeSelectAll($event)"
        [disabled]="fields.editable && fields.editable == 0">
    </ng-multiselect-dropdown>
</div>