import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from 'lexi-form';
import { ViewMenuComponent } from './view-menu.component';
import { TableComponent } from 'lexi-table-v2';
import { ExtendViewComponent } from '../extend-view/extend-view.component';
import { ViewComponent } from 'lexi-view';
import { TestComponent } from '../test/test.component';

const routes: Routes = [
  {
    path: "", children: [
      {path: "", component: ViewMenuComponent, children: [
        {path: "form/:form_identifier", component: FormComponent, data: {subview: true}},
        {path: "form/:form_identifier/:uuid", component: FormComponent, data: {subview: true}},
        {path: "list/:table_identifier", component: TableComponent, data: {subview: true}},
        {path: "list/:table_identifier/:uuid", component: TableComponent, data: {subview: true}},
        {path: "extend/:extand_identifier/:uuid", component: ExtendViewComponent, data: {subview: true}},
        {path: "extend/:extand_identifier", component: ExtendViewComponent, data: {subview: true}},
        {path: "extend/detail/:parent_uuid/:extand_identifier", component: ExtendViewComponent, data: {subview: true}},
        {path: "extend/detail/:parent_uuid/:extand_identifier/:uuid", component: ExtendViewComponent, data: {subview: true}},
        {path: "view/:view_identifier", component: ViewComponent, data: {subview: true}},
        {path: "view/:view_identifier/:uuid", component: ViewComponent, data: {subview: true}},
      ]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule { }
