import { Component, ViewContainerRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-lexi-chart',
  templateUrl: './lexi-chart.component.html',
  styleUrls: ['./lexi-chart.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class LexiChartComponent implements AfterViewInit {

  public structure: any = {};
  public framework: any = {};
  private load: boolean = false;

  @ViewChild("template", { read: ViewContainerRef }) template!: ViewContainerRef

  @Input() set setFramework(val: any) {
    this.framework = val;
    console.log(val)
    if (val.structure) {
      this.structure = val.structure;
      this.init();
    }
  }

  constructor() { }

  ngAfterViewInit() {
    this.load = true;
    this.init();
  }

  async init() {

    if (!this.load) {
      return;
    }

    this.template.clear();

    let viewComponent: any;
    let viewInstance: any;

    let options: any; 
    if(this.structure.chart_type == "pie" || this.structure.chart_type == "doughnut"){
      options = {plugins:{title:{}}}
    }else if(this.structure.chart_type == 'mixed'){
      options = { plugins:{title:{}}, scales: { x: { title: {} }, y: [{ id:"y1", position:'left', title: {} }, { id:"y2", position:'right', title: {} }] } }
    }else{
      options = { plugins:{title:{}}, scales: { x: { title: {} }, y: { title: {} } } }

    }

    if (this.structure.chart_type == 'horizontal-bar') {
      this.structure.chart_type = 'bar'
      options.indexAxis = 'y'
    } else if (this.structure.chart_type == 'vertical-bar') {
      this.structure.chart_type = 'bar'
      options.indexAxis = 'x'
    }

    options.plugins.title.display = this.structure.show_chart_title;
    options.plugins.title.text = this.framework.descp;

    if(this.structure.chart_type !== "pie" && this.structure.chart_type !== "doughnut"){
      if (this.structure.show_axis_title) {
        options.scales.y.title.display = this.structure.show_axis_title;
        options.scales.x.title.display = this.structure.show_axis_title;
        options.scales.y.title.text = this.structure.yaxis_title;
        options.scales.x.title.text = this.structure.xaxis_title;
      }
    }

    switch (this.structure.chart_type) {
      case "line":
      case "bar":
      case "pie":
      case "doughnut":

        viewComponent = (((await import('../bar-chart/bar-chart.component')).BarChartComponent));
        viewInstance = this.template.createComponent(viewComponent);
        viewInstance.instance.setType = this.structure.chart_type;
        viewInstance.instance.setData = this.structure.dataset;
        viewInstance.instance.setLengend = this.structure.show_chart_legend;
        viewInstance.instance.setChartOptions = options;
        break;
      case "scatter":
        viewComponent = (((await import('../scatter/scatter.component')).ScatterComponent));
        viewInstance = this.template.createComponent(viewComponent);
        viewInstance.instance.setScatterData = [{
          label: "Dataset1",
          backgroundColor: 'red',
          data: [
            { x: 50, y: 7 },
            { x: 60, y: 8 },
            { x: 70, y: 8 },
            { x: 80, y: 9 },
            { x: 90, y: 9 },
            { x: 100, y: 9 },
            { x: 110, y: 10 },
            { x: 120, y: 11 },
            { x: 130, y: 14 },
            { x: 140, y: 14 },
            { x: 150, y: 15 }
          ],
        }, {
          label: "Dataset2",
          backgroundColor: 'yellow',
          data: [
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 },
            { x: (Math.random() * 100) + 40, y: Math.random() * 15 }
          ],
        }];
        viewInstance.instance.setScatterLegend = this.structure.show_chart_legend;
        viewInstance.instance.setScatterOption = options;
        break;
    }

  }
}
