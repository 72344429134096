<div class="modal-header d-flex justify-content-between">
    <span>
        <h4 class="modal-title">Filter</h4>
    </span>
    <button class="btn btn-link text-danger" (click)="closeModal()">
        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
    </button>
</div>

<div class="modal-body">
    <ng-container *ngIf="filters.status.status">
        <div class="form-group">
            <label>{{filters.status.title}}</label>
            <select class="form-control" [(ngModel)]="data.status">
                <option *ngFor="let op of filters.status.option" [value]="op.val">{{op.title}}</option>
            </select>
        </div>
    </ng-container>

    <ng-container *ngIf="filters.sort && filters.sort.status">
        <div class="form-group">
            <label>{{filters.sort.title}}</label>
            <div class="row">
                <div class="col-12 col-sm-7 col-md-8">
                    <select class="form-control" [(ngModel)]="data.sequence.key">
                        <option *ngFor="let op of filters.sort.option" [value]="op.val">{{op.title}}</option>
                    </select>
                </div>
                <div class="col-12 col-sm-5 col-md-4">
                    <select class="form-control" [(ngModel)]="data.sequence.type">
                        <option [value]="'ASC'">Ascending</option>
                        <option [value]="'DESC'">Descending</option>
                    </select> 
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="filters.date.status">
        <div class="form-group">
            <label>{{filters.date.title}}</label>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <small>Start Date</small>
                    <input class="form-control" type="date" [(ngModel)]="data.date.start_date">
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <small>End Date</small>
                    <input class="form-control" type="date" [(ngModel)]="data.date.end_date">
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="filters.others && filters.others.length > 0">
        <div class="form-group" *ngFor="let other of filters.others">
            <label>{{other.title}}</label>

            <div [ngSwitch]="other.type">
                <ng-container *ngSwitchCase="'text'">
                    <input class="form-control" type="text" [(ngModel)]="data[other.key]">
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                    <select class="form-control" [(ngModel)]="data[other.key]">
                        <option *ngFor="let opt of other.option" [value]="opt.val">{{opt.title}}</option>
                    </select>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="d-flex flex-row mt-4">
        <button class="btn btn-sm btn-primary w-100 mx-1 fit-width" (click)="submit()">Confirm</button>
        <button class="btn btn-sm btn-dark mx-1 fit-width fit-width-content" (click)="reset()">Reset</button>
    </div>
</div>
