import { NgModule } from '@angular/core';
import { LexiFormModule } from 'lexi-form';
import { LexiTableV2Module } from 'lexi-table-v2';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ViewRoutingModule } from './view-menu.routing.module';
import { ViewMenuComponent } from './view-menu.component';
import { MenuComponent } from '../menu/menu.component';
import { ExtendViewComponent } from '../extend-view/extend-view.component';
import { LexiTableModule } from 'lexi-table';
import { TestComponent } from '../test/test.component';

@NgModule({
  declarations: [
    ViewMenuComponent,
    MenuComponent,
    ExtendViewComponent,
    TestComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ViewRoutingModule,
    LexiTableModule,
    LexiFormModule,
    LexiTableV2Module,
  ]
})
export class ViewMenuModule { }
