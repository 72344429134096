  <div class="d-flex align-items-center vh-100">
    <div class="col-12 col-md-4 p-5 vh-100 bg-white justify-content-center d-flex flex-column" style="z-index:1">
      <div>
        <form #loginForm="ngForm" (submit)="loginForm.valid && submit()" class="sign-wrapper w-100" [ngClass]="{'was-validated': loginForm.submitted && !loginForm.valid}">
          <div class="wd-100p">
            <h3 class="tx-color-01 mg-b-5">{{'dashforge.sign_in' | translate}}</h3>
            <p class="tx-color-03 tx-16 mg-b-40">{{'dashforge.sign_in_welcome_message' | translate}}</p>
            <!-- Branch -->
            <!-- <div class="form-group">
              <label>{{'dashforge.branch' | translate}}</label>
              <select class="form-control" name="branch" #branch="ngModel" [(ngModel)]="data.branch" required>
                <option value="" selected disabled>SELECT BRANCH</option>
                <option *ngFor="let branch of branches" [value]="branch.branch_identifier">{{branch.branch_name}}</option>
              </select>
              <div *ngIf="loginForm.submitted && branch.invalid" class="invalid-feedback">
                <div *ngIf="branch.errors?.['required']">{{'dashforge.branch_required' | translate}}</div>
              </div>
            </div> -->
            <div class="form-group">
              <label>{{'dashforge.branch' | translate}}</label>
              <select class="form-control" name="branch" #branch="ngModel" [(ngModel)]="data.branch" required>
                <option value="" selected disabled>SELECT BRANCH</option>
                <option *ngFor="let branch of branches" [value]="branch.id">{{branch.branch_name}}</option>
              </select>
              <div *ngIf="loginForm.submitted && branch.invalid" class="invalid-feedback">
                <div *ngIf="branch.errors?.['required']">{{'dashforge.branch_required' | translate}}</div>
              </div>
            </div>

            <!-- Email -->
            <div class="form-group">
              <label>{{'dashforge.email' | translate}}</label>
              <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="data.email" [placeholder]="placeholder.login_username_placeholder" required />
              <div *ngIf="loginForm.submitted && email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']">{{'dashforge.email_required' | translate}}</div>
              </div>
            </div>

            <!-- Password -->
            <div class="form-group">
              <div class="d-flex justify-content-between mg-b-5">
                <label class="mg-b-0-f">{{'dashforge.password' | translate}}</label>
              </div>
              <input type="password" class="form-control" name="password" #password="ngModel" [(ngModel)]="data.password" [placeholder]="placeholder.login_password_placeholder" minlength="6" maxlength="16" required />
              <div *ngIf="loginForm.submitted && password.invalid" class="invalid-feedback">
                <div *ngIf="password.errors?.['required']">{{'dashforge.password_required' | translate}}</div>
              </div>
            </div>
            <button class="btn btn-brand-02 btn-block">{{'dashforge.sign_in' | translate}}</button>
          </div>
          <div class="mt-3 d-flex justify-content-between" *ngIf="dsme">
            <a [routerLink]="['/auth/register']">{{'dashforge.register' | translate}}</a>
            <a [routerLink]="['/auth/forgot-password']">{{'dashforge.forgot_password' | translate}}</a>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-8 vh-100 d-none d-md-block video-wrapper bg-dark">
      <!-- <video autoplay loop playsinline poster="https://digitalsme.b-cdn.net/video/my-02.jpg" id="myVideo">
        <source [src]="'https://digitalsme.b-cdn.net/video/my-02.mp4'" type="video/mp4" />
      </video> -->
    </div>
  </div>
