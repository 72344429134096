import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl;
    if(!this.ctrl.value){
      this.ctrl.setValue([])
    }
    this.data = this.ctrl.value;
  }

  public ctrl:any;
  public data:any;
  public columns:any = [{}];
 
  constructor() { }

  addTableRow() {
    let columns = [{}];
    if(this.data[0]) {
      let total_columns = this.data[0].length;

      if(total_columns > 1) {
        columns = [];
        let x = 0;
        while(x < total_columns) {
          columns.push({})
          x++;
        }
      }

    }
    this.data.push(columns)
  }

  removeTableRow() {
    this.data.splice((this.data.length - 1), 1)
  }

  addColumns() {
    this.data.forEach((element:any) => {
      element.push({})
    });
  }

  removeColumns() {
    this.data.forEach((element:any) => {
      element.splice((element.length - 1), 1)
    });
  }

  trackByFn(index:any, item:any) {
    return index
  }
}
