
<div class="d-flex flex-row justify-content-between align-items-center" [ngClass]="{'pb-2 border-bottom mb-2': !hideTitle}">
    <div class="d-flex flex-row">
        <div class="spinner mr-2" *ngIf="loading"></div>
        <h4 class="m-0" *ngIf="!hideTitle">{{structure.title}}</h4>
    </div>
    <div class="d-flex flex-row" *ngIf="submitOnChange">
        <ng-template #buttonsView></ng-template>
    </div>
</div>

<div class="row mt-3">
    <div [class]="form_option.wrapperClass">
        <form [formGroup]="form" (submit)="submit($event)">
            <ng-template #wrapper></ng-template>
            <div class="d-flex flex-row justify-content-between" *ngIf="!submitOnChange && !hideButton">
                <button type="button" *ngIf="structure.redirect && !subview" (click)="redirect()" [disabled]="loading" class="btn btn-sm btn-outline-primary" >{{translate.instant('Cancel')}}</button>    
                <button type="submit" [disabled]="loading" class="btn btn-sm btn-primary">{{translate.instant('Submit')}}</button>
            </div>
        </form>        
    </div>
</div>

<p class="text-muted"><small style="font-size: 50%; opacity: 0.5; float: right;">VERSION 1.0</small></p>
