<div class="flex-fill border">
    <button class="btn btn-outline d-md-none d-block d-flex ms-auto" (click)="viewMenu()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
    </button>
    <div class="d-flex flex-row">
        <div class="col-md-2 d-none d-md-block min-height border-right table-menu-sidebar">
            <lib-menu [data]="groups" [uuid]="uuid" [existing_path]="existing_path"></lib-menu>
        </div>
        <div class="col-md-10 col-12 py-3 min-height card">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>