<button class="btn btn-link d-flex ms-auto d-md-none d-block" (click)="closeMenu()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></button>
<div class="flex-fill pd-y-20">
    <div class="d-flex align-items-center justify-content-between pd-x-10 mg-b-10" *ngFor="let field of groups.fields">
      <span class="tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1">{{translate.instant(field['title'])}} : {{groups.data[field['key']]}}</span>
    </div>
    <nav id="allChannels" class="nav flex-column nav-chat mg-b-20">
        <a [routerLink]="['/detail', existing_path, uuid, menu.type, menu.path, uuid]" [queryParams]="{parent:uuid}" [ngClass]="{'active': getCurrentRoutes() == ('/detail' + '/' + existing_path + '/' + uuid + '/' + menu.type + '/' + menu.path + '/' + uuid)}" class="nav-link"  *ngFor="let menu of groups.menus; let i = index">
            {{translate.instant(menu.title)}}
        </a>
    </nav>
</div>