import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {

  public search:any;
  public showAll = true;
  private timeout:any = null;

  @Input() set setFilter(v:any) {
    if (v) {
      (<HTMLInputElement>document.getElementById("search")).value = v;
      this.showAll = false;
    }
  }

  @ViewChild("filterView", {static: true}) filterView!: ElementRef;

  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor(public translate :LexiTranslationHelper) { }

  detectInput() {
    this.search = (<HTMLInputElement>document.getElementById("search")).value
    this.showAll = this.search.length > 0 ? false : true
    this.outputEvent.emit(this.search)
  }

  reset() {
    this.showAll = true;
    this.outputEvent.emit();
  }

  onKey(event: any) { 
    
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.timeout = null;
      let val = (<HTMLInputElement>document.getElementById("search")).value

      if(val == this.search) {
        return;
      }

      this.search = (<HTMLInputElement>document.getElementById("search")).value
      this.showAll = this.search.length > 0 ? false : true
      this.outputEvent.emit(this.search)
    }, 1000);

  }
}
