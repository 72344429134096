import { Component, AfterViewInit, Inject } from '@angular/core';
import { MenuHelperService } from 'menu-helper';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError} from '@angular/router';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';
import { LexiAuthService } from 'lexi-auth';
import { ApiService } from 'lexi-api';
// import { DsmeDataService } from 'dsme-data';
declare var PerfectScrollbar: any;
declare var $: any;  
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageComponent } from '../language/language.component';
import { WhbDataService } from 'whb-data';
import { CookieService } from 'ngx-cookie-service';
import { LexiTranslationService } from 'lexi-translation';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit {

  public config : any;
  public walletConfig:boolean = false;
  public profile_path : any;
  public setting_path : any;
  public signout_api : any;
  public login_path : any;
  public create_profile_path : any;
  public billing_path:any;
  public support_path:any;
  public menu: any = {};
  public user: any = {};

  public dsme: boolean = false;
  public selected_profile:any = {}
  public profiles: any = [];
  public loading_profile:boolean = true;
  
  public page_loading:boolean = false;
  public toggleSwitch:any = false;
  
  public curriencies:any = [
    { currency: 'myr', label: 'RM' },
    { currency: 'sgd', label: '$' },
    { currency: 'usd', label: '$' }
  ]
  public selected_profile_wallet:any = {}

  constructor(
    private MenuHelper: MenuHelperService,
    private service: LexiDashforgeTopService,
    private auth: LexiAuthService,
    private router: Router,
    private api: ApiService,
    private dataService: WhbDataService,
    private ngbModal: NgbModal,
    private cookieService: CookieService,
    private translationService: LexiTranslationService,
    @Inject("environment") environment: any) { 

    this.dsme = environment.dsme ?? false;

    this.getProfiles();
    this.setting_path = environment.setting_path ?? ['/post/user/setting']
    this.billing_path = environment.billing_path ?? null;
    this.support_path = environment.support_path ?? null;
    this.profile_path = environment.profile_path ?? ['/post/user/profile']
    this.login_path = environment.login_path ?? ["/auth/login"]
    this.create_profile_path = environment.create_profile_path ?? ['/post/profile/create']
    this.signout_api = environment.signout_api ?? '/user/signout';

    this.getHeaderTitle()
    this.config = this.service.get();
    // this.config.app_title = "WAIHONG<span>BROTHERS</span>"
    
    this.router.events.subscribe(event =>{
      if(event instanceof NavigationStart) {
        this.page_loading = true;
      }

      if(event instanceof NavigationCancel || event instanceof NavigationError) {
        this.page_loading = false;
      }

      if (event instanceof NavigationEnd){
        this.page_loading = false;
        this.MenuHelper.setCurrentPath(event.url);
      }
    })
    this.getUser();
    this.menu = this.MenuHelper.get();
    this.toggleSwitch = localStorage.getItem('switcher') == "true" ? true : false;
  }

  async getHeaderTitle() {
    this.config = await this.api.post("/users/get/header/by-branch", {})
  }

  async getUser() {
    let token = await this.cookieService.get('token');
    this.user = await this.api.post('/users/get-detail', {token: token})
  }

  activate(menu: any) : boolean {
    return this.MenuHelper.activate(menu);
  }
  
  async getProfiles() {
    if (!this.dsme) {
      return;
    }
    this.walletConfig = false;
    this.loading_profile = false;
  }
  
  async switchProfile(profile:any) {
    this.walletConfig = false;
  }

  ngAfterViewInit() : void {

    $('body').append('<div class="backdrop"></div>');

    // this.initMenu();
  }

  initMenu() {

    var ps : any = null;

    const initPerfectScroll = function() {
      if (ps) {
        return;
      }

      ps = new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true,
        wheelSpeed: 2,
        wheelPropagation: true,
      });
    }

    $('#mainMenuOpen').on('click touchstart', () => {
      $('body').addClass('navbar-nav-show');
      $('.backdrop').addClass('show');
    });

    $('#mainMenuClose').on('click', () => {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    });

    $('.navbar-menu .with-sub .nav-link').click(function(this: typeof $) {
      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');
  
      if(window.matchMedia('(max-width: 991px)').matches) {
        initPerfectScroll();
        ps.update();
      }
    });

    $('.backdrop').click(function() {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    })

    $('.nav-sub-link').click(function() {
      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').removeClass('navbar-nav-show');
        $('.backdrop').removeClass('show');
      } else {
        $('.nav-item.with-sub').removeClass('show');
      }
    });

  }
  
  async signout() {
    this.api.post(this.signout_api, {});
    this.api.valueAppender("token", null);
    this.api.valueAppender("branch_uuid", null);
    this.auth.update(false)
    this.auth.signout(this.login_path);
  }

  switchLanguage() {
    let modal = this.ngbModal.open(LanguageComponent, {backdrop: true});
    modal.componentInstance.closeEvent.subscribe((val:boolean) => { if(!val) {return} modal.close() })
  }

  constructWallet(){
    let wallet_type = this.curriencies.find((x:any) => x.currency == this.selected_profile.wallet_currency );
    this.selected_profile_wallet = {
      label: wallet_type.label,
      balance: this.selected_profile.wallet_balance
    }
  }

  async getConfig(selectedProfile:any) {    
    // let profileConfig = await this.data.getProfileConfig(selectedProfile)
    // if(profileConfig && profileConfig.profileWallet){
      // this.walletConfig = true;
      // await this.constructWallet();
    // }
    // console.log(this.walletConfig, profileConfig)
  }

  async switcher(targer:any) {

  }

}
