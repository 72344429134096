<div *ngIf="table_not_found">
    <h4 class="m-0">Table Structure Not Found. Contact Administrator for assistant</h4>
</div>

<div class="d-flex flex-column justify-content-start pb-2 px-3" *ngIf="!hide_header">
    <h4 class="m-2"><b>{{structure.title}}</b></h4>
    <p class="text-muted mb-0" *ngIf="structure.descp"><small>{{structure.descp}}</small></p>    
</div>    


<div class="d-flex flex-row justify-content-end pb-2 align-items-center px-3" *ngIf="!hide_header">
    <ng-template #searchView></ng-template> 
    <ng-template #filtersView></ng-template> 
    <ng-template #buttonsView></ng-template>
</div>

<ng-container *ngIf="loading">
    <lib-bar></lib-bar>
</ng-container>

<div #table [class]="table_option.wrapperClass"></div>

<ng-template #navigationView></ng-template>

<p class="text-muted"><small style="font-size: 50%; opacity: 0.5; float: right;">VERSION 2.0</small></p>
