import { NgModule } from '@angular/core';
import { LexiViewerRoutingModule } from './lexi-viewer.routing.module';
import { ViewerComponent } from './viewer/viewer.component';
import { CommonModule } from '@angular/common'
import { ViewerContentComponent } from './viewer/viewer-content/viewer-content.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { LayoutComponent } from './layout/layout.component';
import { FrameworkComponent } from './layout/framework/framework.component';
import { BridgeComponent } from './layout/bridge/bridge.component';
import { PopupComponent } from './layout/popup/popup.component';

@NgModule({
  declarations: [
    ViewerComponent,
    ViewerContentComponent,
    GroupViewComponent,
    LayoutComponent,
    FrameworkComponent,
    BridgeComponent,
    PopupComponent
  ],
  imports: [
    CommonModule,
    LexiViewerRoutingModule
  ],
  exports: [
    
  ],

})
export class LexiViewerModule { }
