<header class="navbar navbar-header navbar-header-fixed">
    <a href="javascript:void(0)" id="mainMenuOpen" class="burger-menu"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>
    <div class="navbar-brand">
      <a [routerLink]="['/']" class="df-logo" [innerHTML]="config.app_title"></a>
    </div><!-- navbar-brand -->
    <div id="navbarMenu" class="navbar-menu-wrapper">
      <div class="navbar-menu-header">
        <a [routerLink]="['/']" class="df-logo" [innerHTML]="config.app_title"></a>
        <a id="mainMenuClose" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>
      </div><!-- navbar-menu-header -->
      <ul class="nav navbar-menu" id="menuView">
        <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">
          <span>{{'dashforge.main' | translate}}</span>
        </li>
        <ng-container *ngFor="let m of menu.primary_menu">
          <li class="nav-item d-block d-sm-block d-md-block d-lg-flex" *ngIf="m.selected" [ngClass]="{'with-sub': m.sub_menu && m.sub_menu.length > 0 && m.enable_child, 'active': activate(m)}">
            <ng-container *ngIf="!m.enable_child">
              <a [routerLink]="[m.path]" class="nav-link">{{ 'menu.'+[m.identifier] | translate}}</a>
            </ng-container>
            <ng-container *ngIf="m.enable_child">
              <ng-container *ngIf="m.sub_menu && m.sub_menu.length > 0">
                <a href="javascript:void(0)" class="nav-link">{{ 'menu.'+[m.identifier] | translate}}</a>
              </ng-container>
              
              <ng-container *ngIf="m.sub_menu && m.sub_menu.length > 0">
                <div class="navbar-menu-sub">
                  <div class="d-lg-flex">
                    <ul *ngFor="let s of m.sub_menu">
                      
                        <li class="nav-label" *ngIf="s.label">{{ 'menu.'+[s.identifier] | translate}}</li>
                        <li class="nav-sub-item" *ngFor="let sm of s.menu" [ngClass]="{'active font-weight-bold': activate(sm)}">
                          <ng-container *ngIf="sm.selected">
                            <hr class="m-0" *ngIf="sm.identifier == 'separator'">
                            <ng-container *ngIf="sm.identifier !== 'separator'">
                              <hr class="m-0" *ngIf="sm.divider" />
                              <span class="nav-label" *ngIf="sm.label">{{ 'menu.'+[sm.identifier] | translate}}</span>
                              <a [routerLink]="[sm.path]" class="nav-sub-link" *ngIf="!sm.label && !sm.divider">
                                {{ 'sub_menu.'+[sm.identifier] | translate}}
                              </a>
                            </ng-container>
                          </ng-container>
                          
                        </li>
  
                    </ul>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </li>
        </ng-container>
        
      </ul>
    </div>
    <div class="navbar-right">      
      <div class="dropdown dropdown-profile">
        <a href="javascript:void(0)" class="dropdown-link" data-toggle="dropdown" data-display="static">
          <div class="d-flex flex-column text-dark me-1 ms-1">
            <span>{{user.branch_name}}</span>
          </div>         
          <div class="avatar avatar-sm"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"/></g></svg></div>
        </a>
        <div class="dropdown-menu dropdown-menu-right tx-13">
          <a [routerLink]="['/notification']" class="dropdown-item">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M1 20v-2.946c1.993-.656 2.575-2.158 3.668-6.077.897-3.218 1.891-6.784 4.873-8.023-.027-.147-.041-.299-.041-.454 0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5c0 .156-.014.309-.042.458 2.987 1.244 3.984 4.813 4.884 8.033 1.103 3.95 1.697 5.423 3.658 6.062v2.947h-7c0 2.208-1.792 4-4 4s-4-1.792-4-4h-7zm14 0h-6c0 1.656 1.344 3 3 3s3-1.344 3-3zm-13-1h20v-1.241c-2.062-.918-2.82-3.633-3.621-6.498-1.066-3.814-2.167-7.758-6.379-7.761-4.21 0-5.308 3.937-6.369 7.745-.8 2.872-1.559 5.593-3.631 6.514v1.241zm11.492-16.345l.008-.155c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5l.008.152c.455-.099.949-.152 1.487-.152.542 0 1.039.054 1.497.155z"/></svg>
            {{'dashforge.notification' | translate}}
          </a>
          <a [routerLink]="profile_path" class="dropdown-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
            {{'dashforge.view_profile' | translate}}
          </a>

          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" (click)="switchLanguage()" class="dropdown-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/></svg>
            {{'dashforge.switch_language' | translate}}
          </a>
          <a href="javascript:void(0)" (click)="signout()" class="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>{{'dashforge.sign_out' | translate}}</a>
        </div>
      </div>
    </div>
  </header>
  <div class="progress-bar" *ngIf="page_loading">
      <span class="progress-bar-fill"></span>
  </div>
