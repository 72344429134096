<div class="form-group">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.075334947245!2d100.4076115145507!3d5.405495296079481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304ac5971e8ae421%3A0xa10f5068264a5443!2sLexi%20Global%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1675605080199!5m2!1sen!2smy" class="w-100" style="border:1px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <div class="row g-0 d-flex justify-content-evenly">
        <div class="form-check col-5">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked (click)="show_address()">
            <label class="form-check-label" for="flexRadioDefault1">
                Search by Address
            </label>
        </div>
        <div class="form-check col-5">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="show_coordinates()">
            <label class="form-check-label" for="flexRadioDefault2">
                Search by Coordinates
            </label>
        </div>
    </div>
</div>

<div class="form-group" [hidden]="coordinates && !address">
    <div class="row g-0 d-flex justify-content-between">
        <div class="col-9">
            <label [for]="field.mapping.key">Enter Address <em *ngIf="field.required">*</em></label>
            <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" required>
        </div>
        <div class="col-3 d-flex align-items-end">
            <button type="submit" class="btn btn-primary w-100">Search</button>
        </div>
    </div>
</div>

<div class="form-group" [hidden]="!coordinates && address">  
    <div class="row g-0 d-flex justify-content-between">
        <div class="col-4">
            <label [for]="field.mapping.key">Enter Latitude <em *ngIf="field.required">*</em></label>
            <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" required>
        </div>
        <div class="col-4">
            <label [for]="field.mapping.key">Enter Longitude <em *ngIf="field.required">*</em></label>
            <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" required>
        </div>
        <div class="col-3 d-flex align-items-end">
            <button type="submit" class="btn btn-primary w-100">Search</button>
        </div>
    </div>
</div>


<!-- 
    <label [for]="fields.mapping.key">{{fields.title}} <em *ngIf="fields.required">*</em></label>

    <select class="form-control form-control-sm" [id]="fields.mapping.key" [formControl]="ctrl" (change)="detectChanges($event)" [disabled]="fields.editable && fields.editable == 0">
        <ng-container *ngIf="options && options.length > 0">
            <option *ngFor="let option of options" [value]="option.val">{{option.title}}</option>
        </ng-container>
        <ng-container *ngIf="!options || options.length == 0">
            <option disabled>No data found.</option>
        </ng-container>
    </select>
 -->