import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LexiTranslationService } from './lexi-translation.service';
import { LexiTranslationHelper } from './lexi-translation-helper.service';

export function initializeTranslateFactory(ModuleService: LexiTranslationService, 
  value: any) { 
  return () => {
    if (value) {
      ModuleService.set(value)
    }
  };
}

export type ConfigFunction = () => any;

@NgModule({
  imports: [
  ],
  exports: [
    TranslateModule,
  ],
  providers: [LexiTranslationHelper]
})

export class LexiTranslationModule { 
  static load(config: {
    func: ConfigFunction,
    key: string
  }): ModuleWithProviders<LexiTranslationModule> {
    
    const TOKEN = new InjectionToken(config.key);

    return {
        ngModule: LexiTranslationModule,
        providers: [
          { provide: TOKEN, useFactory: config.func },
          {
            provide: APP_INITIALIZER,
            useFactory: initializeTranslateFactory,
            deps: [LexiTranslationService, TOKEN],
            multi: true
          }
        ]
    };
  }
}