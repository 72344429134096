import { Component, ViewChild, ViewContainerRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { ApiService } from 'lexi-api';
import { ViewerService } from '../../viewer.service';

@Component({
  selector: 'lib-viewer-content',
  templateUrl: './viewer-content.component.html',
  styleUrls: ['./viewer-content.component.css']
})
export class ViewerContentComponent implements AfterViewInit {

  public identifier:any;
  public framework_type:any;
  public framework_uuid:any;
  public framework:any = {structure:{}};
  public structure:any = {};
  private view_loaded:boolean = false;

  public is_loading:boolean = false;
  public sub_loading : boolean = false;
  private componentView: any;

  @ViewChild("content", {read: ViewContainerRef}) contentView!: ViewContainerRef;

  constructor(private activatedRoute: ActivatedRoute, private api: ApiService, private cdr: ChangeDetectorRef, private viewerService: ViewerService) { 
    this.activatedRoute.params.subscribe((v:any) => {
      this.identifier = v.param_2;
      this.framework_type = v.param_1;
      this.framework_uuid = v.param_3;

      this.init();
    })
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.view_loaded = true;
    this.init();
  }
    
  async init() {
    let paths = await this.getParentPath()
    
    if(!this.view_loaded) {
      return;
    }

    if(!this.sub_loading) {
      this.contentView.clear();
      this.is_loading = true;
    }

    let y = await this.api.post("/view/my-cloud/framework", {current_param_2: this.identifier, current_param_1: this.framework_type, current_param_3: this.framework_uuid, parent_param_2: paths.parent_param_2, structure: this.structure})
    this.contentView.clear();

    if(y.status) {
      this.structure = y;
      this.framework = y.framework;

      if(!this.sub_loading) {
        let vs = await this.viewerService.loadContent(this.framework_type, this.framework, this.structure, this.contentView, this.getParentPath())
        if(this.framework_type == 'list') {
          vs.instance.retrieveTable.subscribe((val:any) =>{ 
            this.structure = val;
            this.sub_loading = false;
            this.init();
          })
        }
      } else {
        await this.updateContent();
      }
    }
    
    this.sub_loading = false;
    this.is_loading = false;
  }

  // async loadContent() {
  //   console.log(this.framework_type)
  //   switch(this.framework_type){
  //     case 'list':
  //       let listComponent = ((await import("lexi-table-v3")).TableComponent);
  //       let listComponentView = this.contentView.createComponent(listComponent);
  //       listComponentView.instance.setFramework = this.framework;
  //       console.log(this.framework, this.structure, this.structure.table)
  //       // listComponentView.instance.identifier = this.framework.identifier;
  //       listComponentView.instance.setStructure = this.structure.table;
  //       listComponentView.instance.setPath = this.getParentPath();
        
  //       listComponentView.instance.retrieveTable.subscribe((val:any) =>{ 
  //         this.structure = val;
  //         this.sub_loading = false;
  //         this.init();
  //       })
  //     break;
      
  //     case 'report':
  //       let reportViewComponent = ((await import("lexi-chart-v2")).LexiChartComponent);
  //       let reportViewInstance = this.contentView.createComponent(reportViewComponent);
  //       reportViewInstance.instance.setFramework = this.framework;
  //     break;

  //     case 'form':
  //       let editableViewComponent = ((await import("lexi-form-v2")).FormComponent);
  //       let editableViewComponentView = this.contentView.createComponent(editableViewComponent);
  //       editableViewComponentView.instance.setFramework = this.framework;
  //     break;

  //     case 'view':
  //       let nonEditableViewComponent = ((await import("lexi-view-v2")).ViewComponent);
  //       let nonEditableViewComponentView = this.contentView.createComponent(nonEditableViewComponent);
  //       nonEditableViewComponentView.instance.setPath = this.getParentPath();
  //       nonEditableViewComponentView.instance.setFramework = this.framework;
  //       nonEditableViewComponentView.instance.frameworkClass = "col-md-6"
  //     break;
  //   }
  // }

  updateContent() {
console.log(this.framework_type)
    switch(this.framework_type){
      case 'list':
        this.componentView.instance.identifier = this.framework.identifier;
        this.componentView.instance.setStructure = this.structure.table;
      break;
      case 'form':
        // componentView.instance.setStructure = this.framework.structure;
        // componentView.instance.setFormIdentifier = this.content.identifier;
      break;
    }
  }

  getParentPath() {
    let parent_path:any = [];
    let parent_paths = this.activatedRoute.parent?.parent?.parent?.snapshot.url;
    parent_paths?.forEach((v:UrlSegment) => {
      parent_path.push(v.path)
    })

    parent_paths = this.activatedRoute.parent?.parent?.snapshot.url;
    parent_paths?.forEach((v:UrlSegment) => {
      parent_path.push(v.path)
    })
    
    parent_paths = this.activatedRoute.parent?.snapshot.url;
    parent_paths?.forEach((v:UrlSegment) => {
      parent_path.push(v.path)
    })
    
    let p = "/"
    if(parent_path.length > 0) {
      p += parent_path.join("/")
    }

    parent_path = p;

    let current_paths = this.activatedRoute.snapshot.url;
    let current_path:any = [];

    current_paths?.forEach((v:UrlSegment) => {
      current_path.push(v.path)
    })

    current_path = current_paths.join("/")

    let data:any = {};

    let parent_params:any = this.activatedRoute.parent?.snapshot.params;
    data.parent_param_1 = parent_params?.param_1;
    data.parent_param_2 = parent_params?.param_2;
    data.parent_param_3 = parent_params?.param_3;
    data.parent_param_4 = parent_params?.param_4;
    data.parent_param_5 = parent_params?.param_5;

    let current_param:any = this.activatedRoute?.snapshot.params;
    data.current_param_1 = current_param?.param_1;
    data.current_param_2 = current_param?.param_2;
    data.current_param_3 = current_param?.param_3;
    data.current_param_4 = current_param?.param_4;
    data.current_param_5 = current_param?.param_5;

    let child_param:any = this.activatedRoute?.firstChild?.snapshot.params;
    data.child_param_1 = child_param?.param_1;
    data.child_param_2 = child_param?.param_2;
    data.child_param_3 = child_param?.param_3;
    data.child_param_4 = child_param?.param_4;
    data.child_param_5 = child_param?.param_5;

    data.current_path = current_path;
    data.parent_path = parent_path;

    return data
  }


}
