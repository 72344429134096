<!-- <div class="form-group">
    <label for="'accordion_num'">Number accordion of column </label>
    <input type="number" class="form-control form-control-sm" id="'accordion_num'" (change)="checkAccordion($event)">
</div>

<div class="card" *ngFor="let col of cols;let i = index;">
   <div class="card-body">
        <div class="form-group">
            <label [for]="'col-title'+[i]">Title {{i+1}} </label>
            <input type="text" class="form-control form-control-sm" [id]="'col-title'+[i]" >
        </div>
        <div class="form-group">
            <label [for]="'col-descp'+[i]">Description {{i+1}}</label>
            <input type="text" class="form-control form-control-sm" [id]="'col-descp'+[i]" >
        </div>
   </div>
</div>

<div class="mb-3"></div> -->


<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <div class="mb-2" *ngFor="let dt of data;let i = index;">
        <div class="input-group">
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="add()">+</button>
            <input type="text" class="form-control form-control-sm" placeholder="Enter title" [(ngModel)]="dt.title" (change)="emitData()">
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="remove(i)">-</button>
        </div>
        <textarea class="form-control form-control-sm" rows="5" placeholder="Enter Description" [(ngModel)]="dt.descp" (change)="emitData()"></textarea>
    </div>
</div>