<div class="mb-3">
    <p class="mb-0"><small><b>{{field.title}}</b></small></p>
    <div [ngSwitch]="field.mapping.media_type">
        <div *ngSwitchCase="'single'">
            <img class="w-100 mb-4" [src]="data[field.mapping.key]">
            <span *ngIf="!data[field.mapping.key]"> - </span>
        </div>
        <div *ngSwitchCase="'multiple'">
            <div *ngFor="let img of data[field.mapping.key]; let i = index;" class="d-flex justify-content-between">
                <button class="btn btn-link p-0 text-left" type="button" (click)="viewImg(content, img)">{{img}}</button>
            </div>
            <span *ngIf="!data[field.mapping.key] || data[field.mapping.key].length === 0"> - </span>
        </div>
    </div>
</div>

<ng-template #content let-modal>
	<div class="modal-body">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        <ng-container *ngIf="preview_type == 'png' || preview_type == 'jpg' || preview_type == 'peg'">
            <img class="w-100 mb-4" [src]="preview" id="popupImage">
        </ng-container>
		<ng-container *ngIf="preview_type == 'mp4' || preview_type == 'mov' || preview_type == 'ime'">
            <video width="100%" height="auto" controls><source [src]="preview" type="video/mp4"></video>
        </ng-container>

        <ng-container *ngIf="preview_type == 'mp3'">
            <audio width="100%" height="auto" controls> <source [src]="preview" type="audio/mp3"></audio>
        </ng-container>
	</div>
</ng-template>
