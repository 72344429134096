export async function customComponent(framework_library_component:any) {

    let module;
    switch(framework_library_component) {
      case "AttendanceManagementComponent":
        module = (await import("whb-hr")).AttendanceManagementComponent
      break;

      case "AttendanceDailyComponent":
        module = (await import("whb-hr")).AttendanceDailyComponent
      break;
  
      case "PayrollStatementComponent":
        module = (await import("whb-hr")).PayrollStatementComponent
      break;
  
      case "PayrollProcessComponent":
        module = (await import("whb-hr")).PayrollProcessComponent
      break;
  
      case "RoleManagementComponent":
        module = (await import("whb-hr")).RoleManagementComponent
      break;
  
      case "CalendarManagementComponent":
        module = (await import("whb-hr")).CalendarManagementComponent
      break;
  
      case "EmployeeMediaComponent":
        module = (await import("whb-hr")).EmployeeMediaComponent
      break;

      case "QuickEmployeeQrComponent": 
        module = (await import("whb-hr")).QuickEmployeeQrComponent
      break;

      case "AttendanceSummaryComponent": 
        module = (await import("whb-hr")).SummaryWorkdayByMonthComponent
      break;

      case "ReportManagementComponent":
        module = (await import("whb-hr")).ReportManagementComponent
      break;

      case "AttendanceVehicleComponent":
        module = (await import("whb-hr")).AttendanceVehicleComponent
      break;

      case "AttendanceMapComponent":
        module = (await import("whb-hr")).AttendanceMapComponent
      break;
    }
  
    return module;
  
  }