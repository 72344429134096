import { Injectable } from '@angular/core';
import { ApiService } from 'lexi-api';
import { CustomService } from './custom.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(private api: ApiService, private custom: CustomService) { }

  async getFramework(identifier:string, type:string, data_uuid?:string) {
    return await this.api.post("/view/my-cloud/framework", {framework_uuid: identifier, framework_type: type, data_uuid: data_uuid})
  }

  async loadContent(framework_type:any, framework:any, structure:any, contentView:any, path:any) {
    let component:any;
    let view:any;

    contentView.clear();

    switch(framework_type){
      case 'list':
        component = ((await import("lexi-table-v3")).TableComponent);
        view = contentView.createComponent(component);

        view.instance.setFramework = framework;
        view.instance.setStructure = structure.table;
        view.instance.setPath = path;
        
        view.instance.retrieveTable.subscribe(async (val:any) =>{ 
          console.log(val)
          await this.retrieveFramework(contentView, path, framework.uuid ?? path.uuid, framework_type, path.layout_data_uuid, path.data_uuid, val)
        })
      break;
      
      case 'report':
        component = ((await import("lexi-chart-v2")).LexiChartComponent);
        view = contentView.createComponent(component);
        view.instance.setFramework = framework;
      break;

      case 'form':
        component = ((await import("lexi-form-v2")).FormComponent);
        view = contentView.createComponent(component);
        view.instance.setPath = path;
        view.instance.setFramework = framework;
        view.instance.setFormOption = {wrapperClass: "col-md-12"}
      break;

      case 'view':
        component = ((await import("lexi-view-v2")).ViewComponent);
        view = contentView.createComponent(component);
        view.instance.setPath = path;
        view.instance.setFramework = framework;
        view.instance.frameworkClass = "col-md-6"
      break;

      case "menu":
        component = ((await import("lexi-menu")).LexiMenuComponent);
        view = contentView.createComponent(component);
        view.instance.setPath = path
        view.instance.setFramework = framework;
        view.instance.autoRedirect = true;
      break;

      case "layout":
        component = ((await import("./layout/layout.component")).LayoutComponent);
        view = contentView.createComponent(component);
        view.instance.setIdentifier = framework.uuid;
        view.instance.setFramework = structure;
      break;

      case "custom":

        let module:any;
        module = await this.custom.invokeCustomComponent(framework.framework_library_component);

        if(!module) {
          return;
        }

        view = contentView.createComponent(module);

        try {
          view.instance.setPath = path;
        } catch($e) { }

      break;
    }

    return view;
  }
  
  sizing(column:any) {
    let size = "";

    if(column.size && column.size != 0) {
      size += " d-md-block col-md-" + column.size 
    } else if (column.size == 0) {
      size += " d-md-none"
    } else {
      size += " d-md-block col-md-12"
    }

    if(column.tablet_size && column.tablet_size != 0) {
      size += " d-sm-block col-sm-" + column.tablet_size 
    } else if (column.tablet_size == 0) {
      size += " d-sm-none"
    } else {
      size += " d-sm-block col-sm-12"
    }

    if(column.mobile_size && column.mobile_size != 0) {
      size += " d-block col-" + column.mobile_size 
    } else if (column.mobile_size == 0) {
      size += " d-none"
    } else {
      size += " d-block col-12"
    }
 
    return size
  }
  
  async retrieveFramework(viewerContent:any, params:any, framework_uuid:any, framework_type:any, layout_data_uuid:any, data_uuid:any, filter_structure:any) {
    let y = await this.api.post("/view/my-cloud/framework", {framework_uuid: framework_uuid, framework_type: framework_type, layout_data_uuid: layout_data_uuid, data_uuid: data_uuid, structure: filter_structure})
    await this.loadContent(y.framework.type, y.framework, y, viewerContent, params)
  }
}
