import { NgModule } from '@angular/core';
import { LexiTableV2RoutingModule } from './lexi-table-v2.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LexiTableV2RoutingModule
  ],
  exports: [
  ]
})
export class LexiTableV2Module { }
