import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() set data (val:any) {
    this.groups = val;
  }
  @Input() existing_path:any;
  @Input() uuid:any;
  @Output() getRoutes = new EventEmitter();
  @Output() close = new EventEmitter();

  public groups:any;
  constructor(private router: Router, public translate: LexiTranslationHelper) { 
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        this.close.emit(true)
      }
    });
  }

  ngOnInit(): void {
  }

  closeMenu(){
    this.close.emit(true)
  }

  getCurrentRoutes() {
    return this.router.url;
  }

}
