<div class="form-group">
    <ng-container *ngIf="options && options.length > 0">
        <label [for]="field.mapping.key">{{fields.title}} <em *ngIf="field.required">*</em>
            <ng-container *ngIf="field.descp">
                <br>
                <small class="text-muted">{{field.descp}}</small>
            </ng-container>
        </label>
        <div *ngFor="let option of options; let i = index">
            <input [formControl]="ctrl" type="radio" class="me-1 mr-1" name="radio_option" [value]="option.val" [id]="fields.mapping.key + '_' + i" >
            <label [for]="fields.mapping.key + '_' + i">{{option.title}}</label>
        </div>
    </ng-container>
    <ng-container *ngIf="!options || options.length == 0">
        <div disabled>No data found.</div>
    </ng-container>
</div>