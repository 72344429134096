import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LexiNotifyService } from 'lexi-notify';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  public btn: any = {};
  private subview_identifier: any;
  private subview_uuid: any;

  @ViewChild("buttonView", {static: true}) buttonView!: ElementRef;

  @Output() functionEmitter = new EventEmitter();
  @Input() identifier:any;
  @Input() view_uuid:any;
  @Input() data:any;

  @Input() set button(val: any) {
    this.btn = val;

    this.btn.title = this.translate.instant(this.btn.title)

    if(val.subview) {
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    switch (val.type) {
      case "link":
        this.buttonView.nativeElement.onclick = () => {

          if(val.subview && this.subview_identifier && this.subview_uuid) {
  
            let actions = val.action.split("/");
  
            let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], actions[2]]
  
            this.router.navigate(routes, val.data || {})
          } else {
            this.router.navigate([val.action], val.data || {})
          }
        }
      break;
      case "extend":
        this.buttonView.nativeElement.onclick = () => {
          if(val.subview && this.subview_identifier && this.subview_uuid) {
            let actions = val.action.split("/");
    
            let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], "detail", this.subview_uuid, actions[2]]
    
            this.router.navigate(routes, val.data || {})
          }
        }
      break;
      case "function":
        this.buttonView.nativeElement.onclick = () => {
          this.functionEmitter.emit(val.action)
        }
      break;
      case "edit":
        this.buttonView.nativeElement.onclick = () => {
          if(val.subview && this.subview_identifier && this.subview_uuid) {
  
            let actions = val.action.split("/");  
            let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], this.identifier, this.view_uuid]

            this.router.navigate(routes, val.data || {})
          } else {
            this.router.navigate([val.action, this.identifier, this.view_uuid], val.data || {})
          }
        }
      break;
      case "copy":
        this.buttonView.nativeElement.onclick = () => {
          this.btn.action = JSON.parse(this.btn.action)
  
          const textArea = document.createElement("textarea"); 
          textArea.value = this.btn.action.key +"/"+ this.data[this.btn.action.variable]; 
          document.body.appendChild(textArea); 

          textArea.focus();
          textArea.select(); 

          try{
            document.execCommand('copy')
            this.notify.success("Copied!")
            console.log("Copied")
          } catch(err) {
            console.error('Unable to copy to clipboard',err)
          }
          document.body.removeChild(textArea)
        }
      break;
    }
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translate: LexiTranslationHelper, private notify: LexiNotifyService) { }

  ngOnInit(): void {
  }
}
