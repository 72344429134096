import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';
import { ApiService } from 'lexi-api';
import { LexiViewService } from '../../lexi-view.service';

@Component({
  selector: 'lib-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {

  public btn: any = {};

  @Input() identifier:any;
  @Input() data:any;

  @Input() set button(val: any) {
    this.btn = val;
    this.btn.title = this.translate.instant(this.btn.title)
  };

  constructor(private translate: LexiTranslationHelper, private api: ApiService, private viewService: LexiViewService) { }

  ngOnInit(): void {
  }

  async actionClick(type:any) {
    let headers = this.btn.action.headerText; 

    var today = new Date();
    var filename = this.identifier + today.getFullYear()+("0" + (today.getMonth() + 1)).slice(-2)+("0" + (today.getDate() + 1)).slice(-2)+ this.data.uuid

    if(type == 'csv'){
      let header = "";
      let values = ""

      switch(this.btn.action.key) {
        case "exportDocDetail":
          for(let x = 0; x < headers.length; x++){
  
            if(typeof this.data[headers[x]['key']] == "object"){
              let detail = "";
              this.data[headers[x]['key']].forEach((obj:any, i:number) => {
                values += (i == 0 ? headers[x]['title'] : ' ') + "," + Object.values(obj).join(',') + "\n"
              });      
            }else {
              values += headers[x]['title'] + "," + JSON.stringify(this.data[headers[x]['key']]) + "\n"
            }
          }
        break;
        case "exportDocList":
        
        break;
      }
      let content = (header ? header + '\n' : '') + values;

      this.viewService.exportCSV(filename, [content])
    }else {

      let header = "";
      let values = "";

      switch(this.btn.action.key) {
        case "exportDocDetail":
          for(let x = 0; x < headers.length; x++){
  
            if(typeof this.data[headers[x]['key']] == "object"){
              this.data[headers[x]['key']].forEach((obj:any, i:number) => {
                header += (i == 0 ? headers[x]['title'] : ' ') + "\n"
                values +=  Object.values(obj).join(',') + "\n"
                // values += (i == 0 ? headers[x]['title'] : ' ') + "," + Object.values(obj).join(',') + "\n"
              });      
            }else {
              header +=  headers[x]['title'] + "\n"
              values +=  JSON.stringify(this.data[headers[x]['key']]) + "\n"
              // values += headers[x]['title'] + "," + JSON.stringify(this.data[headers[x]['key']]) + "\n"
            }
          }
        break;
        case "exportDocList":
        
        break;
      }
      let content = [[[header, values]], [values]];

      this.viewService.exportEXCEL(filename, [content])
    }
    

  }
}
