<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <select class="form-control form-control-sm" [id]="field.mapping.key" [formControl]="ctrl">
        <option [value]="null">Please Select Status</option>
        <option [value]="1">Yes</option>
        <option [value]="0">No</option>
    </select>
</div>