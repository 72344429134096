
<div class="row">
    <div class="{{structure.width}}">
        <div class="d-flex flex-row justify-content-between pb-2 align-items-center border-bottom mb-2">
            <div class="d-flex flex-row">
                <div class="spinner mr-2" *ngIf="loading"></div>
                <h4 class="m-0">{{translate.instant(structure.title ?? ' ')}}</h4>
            </div>

            <section><ng-template #buttonsView></ng-template> </section>
        </div>
        
        <ng-template #wrapper></ng-template>
    </div>
</div>