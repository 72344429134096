import { ChangeDetectorRef, Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

@Component({
  selector: 'lib-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.css']
})
export class GroupViewComponent implements AfterViewInit {

  public identifier:any;
  public current_framework_uuid:any;
  public parent_path:string = "/";
  public data_uuid:any;

  @ViewChild("menuViewer", {read: ViewContainerRef}) menuView!: ViewContainerRef;

  constructor(private activatedRoute:ActivatedRoute, private cdr: ChangeDetectorRef, private router: Router) {
    let parent_paths = this.activatedRoute.parent?.snapshot.url;
    let parent_path:any = [];

    parent_paths?.forEach((v:UrlSegment) => {
      parent_path.push(v.path)
    })
    
    if(parent_path.length > 0) {
      this.parent_path += parent_path.join("/")
    }

    this.activatedRoute.params.subscribe((v:any) => {
      if(this.identifier != v.param_1) {
        this.identifier = v.param_1;
        this.data_uuid = v.param_3
        //this.init(this.identifier, 'menu', this.data_uuid)  
      }
    })

    this.activatedRoute.firstChild?.params.subscribe((v:any) => {
      this.current_framework_uuid = v.param_3
    })
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.init(this.identifier, 'menu', this.data_uuid);
  }

  async init(identifier:string, type:string, uuid:string) {
    this.menuView.clear();
     
    // let menuComponent = ((await import("lexi-menu")).LexiMenuComponent);
    // let menuComponentInstance = this.menuView.createComponent(menuComponent);
    // menuComponentInstance.instance.identifier = identifier;
    // menuComponentInstance.instance.setViewIdentifier = identifier;
    // // menuComponentInstance.instance.uuid = uuid;

    // // menuComponentInstance.instance.viewerType = "horizontal";
    // // menuComponentInstance.instance.parent_path = this.parent_path
    // menuComponentInstance.instance.current_framework_uuid = this.current_framework_uuid
  }


}
