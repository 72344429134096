import { NgModule } from '@angular/core';
import { LexiViewRoutingModule } from './lexi-view.routing.module';



@NgModule({
  declarations: [
  ],
  imports: [
    LexiViewRoutingModule
  ],
  exports: [
  ]
})
export class LexiViewModule { }
