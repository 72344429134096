
<div class="d-flex flex-row justify-content-end pb-2 align-items-center px-3">
    <select class="form-control form-control-sm me-3" style="width:10%" id="perPage" (change)="getPages()">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
    </select>

    <div class="btn-group" role="group">
        <button type="button" class="btn btn-light" [disabled]="pages.page_nat == 1 || pages.current_page == 1" (click)="previous()">&laquo;</button>
        <button type="button" class="btn" [hidden]="pages.current_page -2 <= 0" (click)="selectedPage(pages.current_page -2)">{{pages.current_page -2}}</button>
        <button type="button" class="btn" [hidden]="pages.current_page -1 <= 0" (click)="selectedPage(pages.current_page -1)">{{pages.current_page -1}}</button>
        <button type="button" class="btn active" (click)="selectedPage(pages.current_page)">{{pages.current_page}}</button>
        <button type="button" class="btn" [hidden]="pages.current_page +1 > pages.page_nat" (click)="selectedPage(pages.current_page +1)">{{pages.current_page + 1}}</button>
        <button type="button" class="btn" [hidden]="pages.current_page +2 > pages.page_nat" (click)="selectedPage(pages.current_page +2)">{{pages.current_page + 2}}</button>
        <button type="button" class="btn btn-light" [disabled]="pages.page_nat == 1 || pages.current_page == pages.page_nat" (click)="next()">&raquo;</button>
    </div>
</div>

