import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view.component';
import { ViewRoutingModule } from './view.routing.module';
import { TextComponent } from '../component/text/text.component';
import { YesnoComponent } from '../component/yesno/yesno.component';
import { DateComponent } from '../component/date/date.component';
import { StatusComponent } from '../component/status/status.component';
import { MediaComponent } from '../component/media/media.component';
import { VerticalWarrantyComponent } from '../component/vertical-warranty/vertical-warranty.component';
import { ButtonComponent } from './button/button.component';
import { ExportComponent } from './export/export.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { LinkComponent } from '../component/link/link.component';
import { JsonComponent } from '../component/json/json.component';
import { MultiTextComponent } from '../component/multi-text/multi-text.component';

@NgModule({
  declarations: [
    ViewComponent,
    TextComponent,
    YesnoComponent,
    DateComponent,
    StatusComponent,
    MediaComponent,
    VerticalWarrantyComponent,
    ButtonComponent,
    ButtonDropdownComponent,
    ExportComponent,
    LinkComponent,
    JsonComponent,
    MultiTextComponent
  ],
  imports: [
    CommonModule,
    ViewRoutingModule,
    NgbModule
  ]
})
export class ViewModule { }
