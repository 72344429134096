import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableMenuService } from '../table-menu.service';

@Component({
  selector: 'lib-extend-view',
  templateUrl: './extend-view.component.html',
  styleUrls: ['./extend-view.component.css']
})
export class ExtendViewComponent implements AfterViewInit {

  private extand_identifier: string | undefined;
  private uuid: string | undefined;
  private parentUUID: string | undefined;

  @ViewChild("template", {read: ViewContainerRef}) extand!: ViewContainerRef;

  constructor(private menu: TableMenuService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.parent?.params.subscribe((a:any) => {
      this.parentUUID = a.uuid;
    })

    this.activatedRoute.params.subscribe((val: any) => {
      if (val.uuid) {
        this.uuid = val.uuid;
      }

      if(val.extand_identifier && this.extand_identifier !== val.extand_identifier){
        this.extand_identifier = val.extand_identifier;
        this.initData()
      }

    }) 
  }

  ngAfterViewInit() {
    this.initData()
  }

  initData() {
    this.menu.setVCR(this.extand);
    this.menu.get(this.extand_identifier!, this.uuid!, this.parentUUID!);
  }

}
