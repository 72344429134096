<div class="d-flex flex-row">
    <div class="flex-fill">
        <div class="row border-bottom position-relative p-0 m-0" *ngFor="let row of rows; let i = index">
            <div [class]="viewerService.sizing(column)" *ngFor="let column of row.columns">
                <div class="row position-relative">
                    <div class="d-flex flex-column px-0">
                        <div class="d-flex flex-column" *ngFor="let dt of column.frameworks">
                            <lib-framework [setFramework]="dt" [setParams]="params"></lib-framework>
                        </div>
                    </div>
                    <div [class]="viewerService.sizing(child)" *ngFor="let child of column.columns; let ii = index">
                        <div class="position-relative">
                            <div class="d-flex flex-column px-0">
                                <div class="d-flex flex-column" *ngFor="let dt of child.frameworks">
                                    <lib-framework [setFramework]="dt" [setParams]="params"></lib-framework>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>