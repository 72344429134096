import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.css']
})
export class ImportExportComponent {

  public ctrl:any;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }
  
  constructor() { }

  saveFile(event : any) {
    const file:File = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {

      let csvToArr = (stringVal: any, splitter: any) => {
        const [keys, ...rest] = stringVal
          .trim()
          .split("\r\n")
          .map((item: any) => item.split(splitter));
      
        const formedArr = rest.map((item: any) => {
          let object : any = {};
          keys.forEach((key: any, index: any) => (object[key] = item.at(index)));
          return object;
        });
        return formedArr;
      }

      // Access to content with e.target.result
      let content = e.target.result;
      let csvObj = csvToArr(content, ",");

      this.ctrl.setValue(JSON.stringify(csvObj));

    };
    
    if(file) {
      reader.readAsText(file);
    }
  }

  downloadFile(data: any) {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}

