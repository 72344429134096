import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';


@Component({
  selector: 'lib-text-only',
  templateUrl: './text-only.component.html',
  styleUrls: ['./text-only.component.css']
})
export class TextOnlyComponent {

  public ctrl:any;

  @Input() field: any = {};

}
