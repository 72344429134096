import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableComponent } from './table.component';
import { TableRoutingModule } from './table.routing.module';
import { ButtonComponent } from './button/button.component';
import { FilterComponent } from './filter/filter.component';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';
import { ExportComponent } from './export/export.component';
import { SearchComponent } from './search/search.component';
import { FilterPopupComponent } from './filter/filter-popup/filter-popup.component';
import { FormsModule } from '@angular/forms';
import { BarComponent } from 'whb-loading';

@NgModule({
  declarations: [
    TableComponent,
    ButtonComponent,
    FilterComponent,
    FilterPopupComponent,
    PageNavigationComponent,
    ExportComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    TableRoutingModule,
    NgbModule,
    FormsModule,
    BarComponent
  ]
})
export class TableModule { }
