import { Component, Output, EventEmitter, Input } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FilterPopupComponent } from './filter-popup/filter-popup.component';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {

  protected modalRef:any = NgbModalRef;
  public data:any;

  @Input() set setFilters (val:any) { 
    this.data = val;
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 
  
  constructor(public translate :LexiTranslationHelper, protected modalService: NgbModal) { }

  openModal() {
    this.modalRef = this.modalService.open(
      FilterPopupComponent, {ariaLabelledBy: 'modal-addUser', backdrop: 'static', size: 'lg', keyboard: false, centered: true}
    );
    this.modalRef.componentInstance.setFilters = this.data;
    this.modalRef.componentInstance.close.subscribe(( val:any ) => {
      this.outputEvent.emit(val)
      this.modalRef.close()
    })
  }

}
