import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-multi-text',
  templateUrl: './multi-text.component.html',
  styleUrls: ['./multi-text.component.css']
})
export class MultiTextComponent {

  @Input() field:any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    if(this.field.mapping.loopKey && !this.ctrl.value){
      for(let x = 0; x < this.field.mapping.loopKey.length; x++){
        this.multiField = {...this.multiField, ...this.field.mapping.loopKey[x]}
      }
      this.ctrl.setValue([this.multiField])

    }else if(!this.ctrl.value || this.ctrl.value.length == 0){
      this.multiField = {"data":""}
      this.ctrl.setValue([this.multiField])
    }

    this.list = this.ctrl.value;
  }

  public multiField:any = {};
  public ctrl:any;
  public list:any = []
  constructor() { }

  addText(){
    this.list.push(this.multiField)
  }

  removeText(index:any) {
    if(this.list.length == 1){
      return;
    }
    this.list.splice(index, 1)
  }
}
