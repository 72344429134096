<p>lexi-chart-v2 works!</p>
<div class="row">
    <div class="col-md-6">
        <lib-bar-chart [setType]="chartType" [setData]="data" [setChartOptions]="chartOptions"
            ></lib-bar-chart>

        <select [(ngModel)]="typeSelector" (change)="setType()">
            <option>line</option>
            <option>bar</option>
            <option>pie</option>
            <option>doughnut</option>
            <option>mixed</option>
        </select>

        <div *ngIf="typeSelector == 'line'">
            <input id="setFill" type="checkbox" [(ngModel)]="fillCheck" (click)="setFill()">
            <label for="setFill">fill</label>
        </div>

        <div *ngIf="typeSelector == 'line'">
            <label for="setBarPos">Line Tension</label>
            <input id="line_tension" type="number" (change)="updateOption()" [(ngModel)]="tension" max="0.5" min="0"
                step="0.1">
        </div>

        <div *ngIf="typeSelector == 'bar'">
            <input id="setBarPos" type="checkbox" (click)="setHorizontal()" [(ngModel)]="barHorizontal">
            <label for="setBarPos">Horizontal</label>
        </div>

        <div>
            <button (click)="test()">test</button>
            <button (click)="addNewData()">add data</button><br />
            <input type="checkbox" (change)="updateOption()" id="title" [(ngModel)]="dispTitle"><label
                for="title">Title</label>
            <input type="text" *ngIf="dispTitle" [(ngModel)]="chartTitle" (input)="updateOption()"><br />
        </div>
        <div *ngIf="typeSelector != 'pie' && typeSelector != 'doughnut'">
            <input type="checkbox" (change)="updateOption()" id="axis_title" [(ngModel)]="dispAxis">
            <label for="axis_title">axis title</label>
            <input type="text" *ngIf="dispAxis" [(ngModel)]="yTitle" (input)="updateOption()">
            <input type="text" *ngIf="dispAxis" [(ngModel)]="xTitle" (input)="updateOption()"><br />

            <input type="checkbox" (change)="updateOption()" id="legend" [(ngModel)]="dispLegend"><label
                for="legend">Legend</label><br />
        </div>

        <lib-scatter [setScatterData]="scatterData" [setScatterOption]="chartOptions"></lib-scatter>
    </div>

</div>