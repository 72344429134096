import { ElementRef, EventEmitter } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  public btn: any = {};
  private subview_identifier: any;
  private subview_uuid: any;

  @ViewChild("buttonView", {static: true}) buttonView!: ElementRef;
  @Output() functionEmitter = new EventEmitter();
  @Input() set button(val: any) {
    this.btn = val;

    this.btn.title = this.translate.instant(this.btn.title)

    if(val.subview) {
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    if (val.type == 'link' && val.action) {
      this.buttonView.nativeElement.onclick = () => {

        if(val.subview && this.subview_identifier && this.subview_uuid) {

          let actions = val.action.split("/");

          let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], actions[2]]

          this.router.navigate(routes, val.data || {})
        } else {
          this.router.navigate([val.action], val.data || {})
        }
      }
    }else if(val.type == 'extend' && val.action){
      this.buttonView.nativeElement.onclick = () => {
        if(val.subview && this.subview_identifier && this.subview_uuid) {
          let actions = val.action.split("/");
  
          let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], "detail", this.subview_uuid, actions[2]]
  
          this.router.navigate(routes, val.data || {})
        }
      }
    }else if(val.type == 'function') {
      this.buttonView.nativeElement.onclick = () => {
        this.functionEmitter.emit(val.action)
      }
    }
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translate: LexiTranslationHelper) { }

}
