<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <lib-lexi-wysiwyg [setData]="value" (getContent)="getData($event)" ></lib-lexi-wysiwyg>
</div>

<!-- <input [formControl]="ctrl" type="hidden" class="form-control form-control-sm" [id]="field.mapping.key" required> -->
