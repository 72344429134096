<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
      <ng-container *ngIf="field.descp">
        <br>
        <small class="text-muted">{{field.descp}}</small>
      </ng-container>
    </label>
    <div class="custom-file mb-3">
      <input [formControl]="ctrl" type="button" (click)="showResources()" class="custom-file-input" [id]="field.mapping.key" name="filename">
      <label class="custom-file-label" [for]="field.mapping.key">
        Choose File
      </label>
    </div>
    <ul class="list-group w-100 border p-2" *ngIf="data.length > 0" style="max-height: 300px; overflow: auto;">
      <li class="list-group-item" *ngFor="let dt of data; let i = index">
        <div class="d-flex flex-row w-100 align-items-center d-flex justify-content-between">
          <div class="img-thumbnail me-2" [style.background-image]="'url(\'' + dt.key + '\')'" style="width:50px; height: 50px; background-size: cover; background-repeat: no-repeat; background-position: center;" ></div>
          <div class="flex-grow-1" style="text-overflow: ellipsis; overflow:hidden; white-space:nowrap">
            <span class="px-2">{{dt.filename}}</span>
          </div>
          <button type="button" class="btn-close" aria-label="Close" (click)="removeItem(i)">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg> -->
          </button>
        </div>
      </li>
    </ul>

    <!-- <div class="container" *ngIf="data">
      <ul>
        <li *ngFor="let dt of data" class="col-3" [style.background-image]="'url('+ dt.key +')'" style="height: 150px; background-size: cover; background-repeat: no-repeat; background-position: center; " >
          <span style="text-overflow: ellipsis; word-wrap: break-word;">{{dt.key}}</span>
          <!-- {{dt.filename}} {{dt.filetype}} 
        </li>
      </ul>
    </div> -->

    <!-- <table>
      <div *ngFor="let dt of data">
        <tr>
          <td>
            <div [style.background-image]="'url('+ dt.key +')'" style="height: 100px; width: 100px; background-size: cover; background-repeat: no-repeat; background-position: center; "></div>
          </td>
          <td>
            <p class="m-0" style="text-overflow: ellipsis; word-wrap: break-word;">{{dt.key}}</p>
          </td>
        </tr>
      </div>
    </table> -->

</div>
